// Clients
// import Login from '@/views/user/Login'
// Mail
// import Compose from '@/views/mail/Compose'
// import Inbox from '@/views/mail/Inbox'
// import Message from '@/views/mail/Message'
// Containers
const Full = () => import(/* webpackChunkName: "Full" */ "@/containers/Full")
// Sales
const Sales = () => import(/* webpackChunkName: "Sales" */ "@/views/sales/Sales_Main")
// User
const Login = () => import(/* webpackChunkName: "Login" */ "@/views/user/Login")
// Calendar
const StudioCalendar = () => import(/* webpackChunkName: "StudioCalendar" */ "@/views/calendar/Calendar_Manager")
// Work
const WorkoutsOverview = () => import(/* webpackChunkName: "WorkoutsOverview" */ '@/views/workouts/workouts_overview.vue')
const WorkoutsWorkout = () => import(/* webpackChunkName: "WorkoutsWorkout" */ '@/views/workouts/workouts_workout.vue')
const WorkoutsExercise = () => import(/* webpackChunkName: "WorkoutsExercise" */ '@/views/workouts/workouts_exercise.vue')
// Resources
const Resources = () => import(/* webpackChunkName: "Resources" */ '@/views/resources/Resources')
// const RessourcesPersonView = () => import(/* webpackChunkName: "resources" */ '@/views/resources/Ressources_Personview')
// const Kiosk = () => import('@/views/client/Kiosk')
const FinancialOverview = () => import(/* webpackChunkName: "FinancialOverview" */ '@/views/finance/Financial_Overview')
const FinancialInvoices = () => import(/* webpackChunkName: "FinancialInvoices" */ '@/views/finance/Financial_Invoices')
const Client = () => import(/* webpackChunkName: "clients" */ '@/views/client/Client')
const Clients = () => import(/* webpackChunkName: "clients" */ '@/views/clients/Clients')
// Company
const CompanyServices = () => import(/* webpackChunkName: "Company" */ '@/views/company/Company_Services.vue')
const CompanyStaff = () => import(/* webpackChunkName: "Company" */ '@/views/company/Company_Staff')
const CompanyLocation = () => import(/* webpackChunkName: "Company" */ '@/views/company/Company_Location')
const CompanyBasicInformation = () => import(/* webpackChunkName: "Company" */ '@/views/company/Company_Basicinformation')
// Dashboards
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/views/dashboards/Dashboard')
const Forecast = () => import(/* webpackChunkName: "Dashboard" */ '@/views/reports/forecast')
const Overview = () => import(/* webpackChunkName: "Dashboard" */ '@/views/reports/overview')
// Tasks
const TasksOverview = () => import(/* webpackChunkName: "TaskOverview" */ "@/views/tasks/tasks_overview")
// Settings
const Settings = () => import(/* webpackChunkName: "Settings" */ '@/views/settings/index')
// import CompanyServices from '@/views/company/Company_Services'
// Vue.config.productionTip = false
export default [
  {
    path: '/',
    redirect: '/kalender',
    name: 'Home',
    component: Full,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'kalender',
        name: 'Kalender',
        component: StudioCalendar
      },
      {
        path: 'kunden',
        name: 'Kunden',
        component: Clients
      },
      {
        path: 'kunde',
        name: 'Kunde_Editieren',
        component: Client
      },
      {
        path: 'tasks',
        name: 'Aufgabenübersicht',
        component: TasksOverview
      },
      {
        path: 'verkauf',
        name: 'Verkauf',
        component: Sales
      },
      // {
      //  path: 'ressourcenpersonview',
      //  name: 'ArbeitsplanungProPerson',
      //  component: RessourcesPersonView
      // },
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'reports',
        redirect: '/reports/overview',
        name: 'Reports',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'overview',
            name: 'Überblick',
            component: Overview
          },
          {
            path: 'forecast',
            name: 'Ausblick',
            component: Forecast
          }
        ]
      },
      {
        path: 'work',
        redirect: '/work/resources',
        name: 'Work',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'resources',
            name: 'Arbeitsplanung',
            component: Resources
          },
          {
            path: 'workouts',
            name: 'Workouts',
            component: WorkoutsOverview
          },
          {
            path: 'workout',
            name: 'Workout',
            component: WorkoutsWorkout
          },
          {
            path: 'exercise',
            name: 'Exercise',
            component: WorkoutsExercise
          },
          {
            path: 'services',
            name: 'Leistungen',
            component: CompanyServices
          }
        ]
      },
      {
        path: 'finanzen',
        redirect: '/finanzen/uebersicht',
        name: 'Finanzen',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'uebersicht',
            name: 'UebersichtFinanzen',
            component: FinancialOverview
          },
          {
            path: 'ausgangsrechnungen',
            name: 'Ausgangsrechnungen',
            component: FinancialInvoices
          }
        ]
      },
      {
        path: 'unternehmen',
        redirect: '/unternehmen/basisinformationen',
        name: 'Unternehmen',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'Basisinformationen',
            name: 'Basisinformationen',
            component: CompanyBasicInformation
          },
          {
            path: 'studio',
            name: 'Studios',
            component: CompanyLocation
          },
          {
            path: 'mitarbeiter',
            name: 'Mitarbeiter',
            component: CompanyStaff
          }
          // {
          //  path: 'kiosk',
          //  name: 'Kiosk',
          //  component: Kiosk
          // }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      },
      { path: '/:pathMatch(.*)*', component: Dashboard }
    ]
  }
]
