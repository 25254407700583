export const dbqueue = {
    namespaced: true,
    state: () => ({
        db_pending: [],
        db_completed: [],
        db_active: {}
    }),
    actions: {
        addJob({ commit, state, dispatch }, job ){
            commit( 'addPendingJob', job );

            if( Object.keys( state.db_active ).length === 0 ){
                dispatch('startNextJob');
            }
        },
        startNextJob({ commit, state, dispatch }){
            if( Object.keys( state.db_active ).length > 0 ){
              commit( 'addCompletedJob', state.db_active );
            }

            if( state.db_pending.length > 0 ) {
              // commit( 'calldatabasequeryworker', state.db_pending[0], { root: true })
              commit( 'setActiveJob', state.db_pending[0] );
              commit( 'popCurrentJob' );
              dispatch( 'calldatabaseworker', state.db_active, { root: true })
            } else {
              commit( 'setActiveJob', {} );
            }
        }
    },
    mutations: {
        addPendingJob( state, job ){
            state.db_pending.push( job );
        },

        setActiveJob( state, job ){
            state.db_active = job;
        },

        popCurrentJob( state ){
            state.db_pending.shift();
        },

        addCompletedJob( state, job ){
            state.db_completed.push( job );
        }
    },
    getters: {
        PENDING( state ){
            return state.db_pending;
        },

        ACTIVE( state ){
            return state.db_active;
        },

        COMPLETED( state ){
            return state.db_completed;
        }
    }
}
