export const serverqueue = {
    namespaced: true,
    state: () => ({
        server_pending: [],
        server_completed: [],
        server_active: {}
    }),
    actions: {
        addJob({ commit, state, dispatch }, job ){
            commit( 'addPendingJob', job );
            if( Object.keys( state.server_active ).length === 0 ){
                dispatch('startNextJob');
            }
        },
        startNextJob({ commit, state }){
            if ( Object.keys( state.server_active ).length > 0 ){
                commit( 'addCompletedJob', state.server_active );
            }

            if ( state.server_pending.length > 0 ){
                commit( 'calldatabaseserverworker', state.server_pending[0], { root: true })
                commit( 'setActiveJob', state.server_pending[0] );
                commit( 'popCurrentJob' );
            } else {
                commit( 'setActiveJob', {} );
            }
        }
    },
    mutations: {
        addPendingJob( state, job ){
            state.server_pending.push( job )
            console.log(job)
            console.log(state.server_pending)
            console.log(state.server_completed)
        },

        setActiveJob( state, job ){
            state.server_active = job
            console.log(state.server_active)
        },

        popCurrentJob( state ){
            state.server_pending.shift()
        },

        addCompletedJob( state, job ){
            state.server_completed.push( job )
        }
    },
    getters: {
        PENDING( state ){
            return state.server_pending;
        },

        ACTIVE( state ){
            return state.server_active;
        },

        COMPLETED( state ){
            return state.server_completed;
        }
    }
}
