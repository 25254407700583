import Dexie from 'dexie'
console.log('db_config.js start auf : ' + new Date().toString())
const mgconfig = new Dexie('mycrogym_studio_config')
mgconfig.version(1).stores({
  user: '++id, em',
  companies: '++id',
  params: '++param'
})
mgconfig
  .open()
  .then(function(result) {
    console.log(result.isOpen())
    mgconfig.tables.forEach(function (table) {
      console.log(table.name)
      mgconfig
        .table(table.name)
        .count()
    })
  })
  .catch (function (err) {
    console.error('Failed to open db: ' + (err.stack || err))
  })
mgconfig.on("blocked", function () { console.log('DB ist geblocked')})
mgconfig.on("close", function () { console.log('DB wurde geschlossen')})
export default mgconfig
