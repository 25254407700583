// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// 'use strict'
import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import Router from 'vue-router'
import routes from './routes'
import VeeValidate from 'vee-validate'
import { messages, attributes } from 'vee-validate/dist/locale/de'
import Multiselect from 'vue-multiselect'
import { sync } from 'vuex-router-sync'
import VueResource from 'vue-resource'
import VueFormWizard from 'vue-form-wizard'
import Dexie from 'dexie'
import Popover from 'vue-js-popover'
import bcrypt from 'bcrypt-nodejs'
import interact from "interactjs"
import mgconfig from '../public/js/db_config.js'
// var mgconfig = new Dexie('mycrogym_studio_config')

// import databaseworkersource from 'worker-loader!../public/js/query.worker.js'
const dbversion = '20230628'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// let mgconfig
let mgconfiginitiated = false
const databaseworker = new Worker('/js/query.worker.js?v=1.012')
const databaseworkerservercom = new Worker('/js/server.worker.js?v=1.010')
async function initiatemgconfig () {
  console.log('Läuft')
  console.log(mgconfiginitiated)
  console.log(mgconfig.isOpen())
  if (mgconfiginitiated === false && false) {
    console.log('geht')
    mgconfiginitiated = true
    let result = {}
    result['user'] = '++id, em'
    result['companies'] = '++id'
    result['params'] = '++param'
    mgconfig
      .version(1)
      .stores(result)
    let result2 = await mgconfig.open()
    return result2
  } else {
    return false
  }
}
async function checkfordbversion () {
  console.log('Check DB Version')
  let result = await mgconfig
    .table('params')
    .get({param: 'dbversion'})
    .then(function (result) {
      if (result && result.version === dbversion) {
        console.log('version ist dieselbe')
        store.state.config.initalserverdatarequestdone = true
        return true
      } else if (result && result.version !== dbversion) {
        // forceSWupdate()
        console.log('version ist nicht dieselbe')
        // Set new dbversion and set initialserverupdate === false
        store.state.config.initalserverdatarequestdone = false
        console.log('new db version')
        // socket.emit('sendpwafiles', dbversion)
        Dexie.delete('mycrogym_studio').then(function () {
          // mg = new Dexie('mycrogym_studio')
          store.commit('serverrefresh', true)
          console.log('mycrogym_studio gelöscht')
          store.state.config.refreshdatabase = true
        })
        return false
      } if (!result) {
        console.log('huch')
        return false
        // store.state.config.initalserverdatarequestdone = false
        // Dexie.delete('mycrogym_studio').then(function () {
          // mg = new Dexie('mycrogym_studio')
        //   store.commit('serverrefresh', true)
        //   console.log('mycrogym_studio gelöscht')
        //   store.state.config.refreshdatabase = true
        // })
        // mgconfig
        //   .table('companies')
        //   .toArray()
        //   .then(function (dataset) {
        //     console.log(dataset)
        //   })
        // mgconfig
        //  .table('params')
        //  .put({param: 'dbversion', version: dbversion})
      }
    })
  return result
}
function checkdeviceid (data) {
  console.log('Check Deviceid')
  console.log(data)
  mgconfig
    .table('user')
    .get({"id": String(data.id)})
    .then(function (result) {
      console.log(result)
      if (result) {
        return result
      } else {
        return null
      }
    })
}
async function checkifuserisinstorage (credentials) {
  console.log(credentials.email)
  console.log(mgconfig)
  console.log(mgconfig.isOpen())
  let mail = credentials.email.toLowerCase()
  console.log(mail)
  let result = await mgconfig
    .table('user')
    .get({'em': mail})
    .then(function (result) {
      console.log(result)
      if (result) {
        console.log(result)
        return result
      } else {
        console.log('result2')
        return {}
      }
    })
  return result
}
Vue.use(Vuex)
Vue.use(Popover, { tooltip: true })
Vue.use(VueResource)
Vue.use(VueFormWizard)
Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: '/',
  linkActiveClass: 'open active',
  routes
})
// Progress(router)
// Languagesettings
Vue.config.lang = 'de'
// Vue.use(Avatar)
Vue.component('multiselect', Multiselect)
Vue.prototype.$socket = {
  emit(data,data2) {
    console.log(data + ' ' + data2)
    // store.dispatch('serverqueue/addJob', {
    //  id: data2.id,
    //  function: data,
    //  description: '',
    //  data: data2
    // })
    databaseworkerservercom.postMessage({'cmd':'socketProxy','function': data, 'data': data2 })
  }
}
/* eslint no-extend-native: ["error", { "exceptions": ["Date"] }] */
Date.prototype.monthDays = function () {
  let d = new Date(this.getFullYear(), this.getMonth() + 1, 0)
  return d.getDate()
}
Date.prototype.dayOfYear = function(){
  var j1= new Date(this)
  j1.setMonth(0, 0)
  return Math.round((this-j1)/8.64e7)
}
Date.prototype.formatDate = function (format) {
  let date = this
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  let monthnames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli','August','September','Oktober','November','Dezember']
  let daynames = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  let fulldaynames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
  if (!format) {
    format = "MM/dd/yyyy"
  }
  if (format.indexOf("YYYY") > -1) {
    format = format.replace("YYYY", year.toString())
  } else if (format.indexOf("YY") > -1) {
    format = format.replace("YY", year.toString().substr(2, 2))
  }
  if (format.indexOf("MMMM") > -1) {
    format = format.replace("MMMM", monthnames[date.getMonth()])
  } else if (format.indexOf("MM") > -1) {
    format = format.replace("MM", month.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("dddd") > -1) {
    format = format.replace("dddd", fulldaynames[date.getDay()])
  } else if (format.indexOf("dd") > -1) {
    format = format.replace("dd", daynames[date.getDay()])
  }
  if (format.indexOf("DD") > -1) {
    format = format.replace("DD", day.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("HH") > -1) {
    format = format.replace("HH", hours.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("hh") > -1) {
    if (hours > 12) {
      hours -= 12
    }
    if (hours === 0) {
      hours = 12
    }
    format = format.replace("hh", hours.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("mm") > -1) {
    format = format.replace("mm", minutes.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("ss") > -1) {
    format = format.replace("ss", seconds.toString().replace(/^(\d)$/, '0$1'))
  }
  return format
}
Date.prototype.formatUTCDate = function (format) {
  let date = this
  let day = date.getDate()
  let month = date.getUTCMonth() + 1
  let year = date.getUTCFullYear()
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  let seconds = date.getUTCSeconds()
  let monthnames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli','August','September','Oktober','November','Dezember']
  let daynames = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  if (!format) {
    format = "MM/dd/yyyy"
  }
  if (format.indexOf("YYYY") > -1) {
    format = format.replace("YYYY", year.toString())
  } else if (format.indexOf("YY") > -1) {
    format = format.replace("YY", year.toString().substr(2, 2))
  }
  if (format.indexOf("MMMM") > -1) {
    format = format.replace("MMMM", monthnames[date.getMonth()])
  } else if (format.indexOf("MM") > -1) {
    format = format.replace("MM", month.toString().replace(/^(\d)$/, '0$1'))
  }
  format = format.replace("dd", daynames[date.getDay()])
  format = format.replace("DD", day.toString().replace(/^(\d)$/, '0$1'))
  if (format.indexOf("HH") > -1) {
    format = format.replace("HH", hours.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("hh") > -1) {
    if (hours > 12) {
      hours -= 12
    }
    if (hours === 0) {
      hours = 12
    }
    format = format.replace("hh", hours.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("mm") > -1) {
    format = format.replace("mm", minutes.toString().replace(/^(\d)$/, '0$1'))
  }
  if (format.indexOf("ss") > -1) {
    format = format.replace("ss", seconds.toString().replace(/^(\d)$/, '0$1'))
  }
  return format
}
Date.prototype.getWeekNumber = function () {
  let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()))
  let dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1))
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
}
Date.prototype.toIsoString = function () {
  let tzo = -this.getTimezoneOffset()
  let dif = tzo >= 0 ? '+' : '-'
  let pad = function (num) {
    let norm = Math.floor(Math.abs(num))
    return (norm < 10 ? '0' : '') + norm
  }
  return this.getFullYear() +
      '-' + pad(this.getMonth() + 1) +
      '-' + pad(this.getDate()) +
      ' ' + pad(this.getUTCHours()) +
      ':' + pad(this.getUTCMinutes()) +
      ':' + pad(this.getUTCSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60)
}
function convertDateForIos (date) {
  if (date.indexOf(' ') >= 0) {
    let arr = date.split(/[- :]/)
    date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5])
  }
  return date
}

const veevalidateconfig = {
  errorBagName: 'errors', // change if property conflicts.
  fieldsBagName: 'fields',
  delay: 5,
  locale: 'de',
  dictionary: {
    de: { messages: messages, attributes: attributes }
  },
  strict: true,
  classes: false,
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'has-success', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty' // control has been interacted with
  },
  events: 'input|blur',
  inject: false,
  validity: true,
  aria: true
}
Vue.use(VeeValidate, veevalidateconfig)
VeeValidate.Validator.extend('verify_password', {
  getMessage: field => `Das Password muss folgende Bestandteile aufweisen: 2 Grossbuchstabe, 2 Kleinbuchstabe, 1 Nummer und eine spezielles Zeichen (z.B.. , . _ & ? etc)`,
  validate: value => {
    // let strongRegex = new RegExp("^(?=(?:[^A-Z]*[A-Z]){2})(?=(?:[^a-z]*[a-z]){2})(?=[^!@#$&*]*[!@#$&*])(?=(?:[^0-9]*[0-9]){2})")
    let strongRegex = new RegExp("^(?=(?:[^A-Z]*[A-Z]){2})(?=(?:[^a-z]*[a-z]){2})(?=(?:[^0-9]*[0-9]){2})")
    return strongRegex.test(value)
  }
})

// Store
import { serverqueue } from './store/modules/server_queue.js'
import { dbqueue } from './store/modules/db_queue.js'
const store = new Vuex.Store({
  modules: {
    serverqueue,
    dbqueue
  },
  state: {
    Userdata: {
      id: '',
      timestamp: '',
      credentials: [],
      datasource: {},
      useracls: {},
      deviceid: '',
      userrightsbycompany: {},
      companies: [],
      ActiveCompanyResource: {},
      CompaniesResources: [],
      UserCompaniesIds: []
    },
    Acl: {
      timestamp: '',
      levels: [],
      levels_companies: [],
      levels_mapping: [],
      levels_rights: [],
      appcomponents: [],
      company_acl: []
    },
    dateconfig: {
      ActiveDate: new Date().formatDate("YYYY-MM-DD"),
      ActiveWeek: new Date().getWeekNumber(),
      ActiveMonth: new Date().getMonth(),
      ActiveYear: new Date().getFullYear(),
      DateTimer: [],
      StartOfWeek: 1,
      ShowTimeStepsInMinutes: 60,
      ShowResources: true,
      showarchivedtrainings: false,
      ViewedTimeStepsInMinutes: 15,
      cellheight: 50,
      cellwidth: '',
      sizechanged: '',
      day: {
        cellheight: '',
        cellwidth: ''
      },
      CalendarView: 'week',
      cachingevent: {
        month: '',
        start: '',
        end: ''
      }
    },
    history: {
      calendar: [],
      tabs: [],
      users: []
    },
    customrequests: [],
    modals: {
      loadingstatus: {
        show: false
      },
      editevent: {
        show: false,
        data: {},
        times: {}
      },
      editequipment: {
        show: false,
        modus: '',
        kindofequipmentid: ''
      },
      editroom: {
        show: false,
        data: {}
      },
      editcontractoffering: {
        show: false,
        data: {},
        modus: ''
      },
      editimageratio: {
        show: false,
        data: [],
        standardratios: [],
        name: ''
      },
      editimage: {
        show: false,
        data: []
      },
      mediapreview: {
        show: false,
        data: []
      },
      mediabrowser: {
        show: false,
        settings: []
      },
      addcontract: {
        show: false,
        data: {},
        type: ''
      },
      editcontract: {
        show: false,
        data: {},
        bookings: []
      },
      duplicateevents: {
        show: false,
        date: [],
        rooms: []
      },
      newclient: {
        show: false
      },
      edittask: {
        show: false,
        modus: 'new',
        id: '',
      }
    },
    activesettingsperview: {},
    pwa: {
      indexedDB: false,
      serviceworkeravailable: false,
      serviceworkerinstalled: false,
      serviceworkerready: false,
      systemreadytoaddapptohome: false,
      appisalreadyinstalledonhome: false,
      appinstalledtohome: false,
      applaunchedfromhomescreen: false,
      databaseworkerready: false,
      dbloading: {},
      filestocache: [],
      pdfreceived: false,
      pdfcache: []
    },
    config: {
      reportsyear: new Date().getFullYear(),
      waitingforresponse: false,
      passwordwrong: false,
      authretries: 0,
      appversion: '1.000.240',
      appversionnumber: 1000240,
      databaseversion: '2',
      databaseversionnumber: 2,
      refreshdatabase: false,
      isConnected: false,
      isAuthenticated: false,
      isLoggedin: false,
      isLoading: false,
      CacheisLoading: false,
      eventsloaded: false,
      bookingsloaded: false,
      isOnline: 'online',
      hasLoaded: false,
      rightsloaded: false,
      loggedoutbyuser: true,
      token: '',
      lasturlpath: '',
      registerpossible: false,
      initalserverdatarequestdone: false,
      initalworkerdatarequestdone: false,
      initalscriptrequestdone: false,
      deviceid: '',
      CalenderViewMode: 'studio',
      ResolutionMode: '',
      serverconnectionlostdate: '',
      ActiveCompany: '',
      ActiveLocation: '',
      ActiveRoom: '',
      ActiveClient: '',
      ActiveUserCompanyACLRights: [],
      ActiveUserCompanyACLLevel: [],
      ActiveUserCompanyResource: [],
      ActiveExerciseId: '',
      ActiveWorkoutId: '',
      ActiveProgramId: '',
      workoutoverviewactivetab: 0,
      month_cache: {
        start: '',
        end: '',
        year: '',
        month: '',
        companies: '',
        initialcacheupdatedone: false
      },
      lastaddedclient: {},
      lastaddedbooking: {},
      hoverservice: '',
      AppVersion: '',
      tablesarrived: false,
      tables: [
        { name: 'app_components', description: 'Applikationskomponenten', index: '++id', loaded: false },
        { name: 'calendar_bookings', description: 'Trainingsbuchungen', index: '++id,clientid', loaded: false },
        { name: 'calendar_events', description: 'Trainings', index: '++id', loaded: false },
        { name: 'clients_info', description: 'Kundeninformationen', index: '++id', loaded: false },
        { name: 'clients_infoconfig', description: 'Kundeninformationen', index: '++id', loaded: false },
        { name: 'clients_mapping', description: 'Kundeninformationen', index: '++id', loaded: false },
        { name: 'clients_mapping_info', description: 'Kundeninformationen', index: '++id', loaded: false },
        { name: 'communication_accounts', description: 'Kommunikationsaccounts', index: '++id', loaded: false },
        { name: 'communication_accounts_emailaddresses', description: 'Kommunikationskonfiguration', index: '++id', loaded: false },
        { name: 'communication_accounts_types', description: 'Kommunikationstypen', index: '++id', loaded: false },
        { name: 'communication_outbox_internal_emails', description: 'interne Mails', index: '++id,clientid', loaded: false },
        { name: 'companies', description: 'Unternehmen', index: '++id', loaded: false },
        { name: 'companies_connected', description: 'Verbundene Unternehmen', index: '++id', loaded: false },
        { name: 'companies_acl_level', description: 'Unternehmensberechtigungslevel', index: '++id', loaded: false },
        { name: 'companies_acl_level_rights', description: 'Unternehmensberechtigungslevelrechte', index: '++id', loaded: false },
        { name: 'companies_acl_mapping', description: 'Unternehmensberechtigungslevel', index: '++id', loaded: false },
        { name: 'companies_clients_groups', description: 'Kundengruppen', index: '++id', loaded: false },
        { name: 'companies_healthstate', description: 'Kundendetails', index: '++id', loaded: false },
        { name: 'companies_pricerules', description: 'Preisregeln', index: '++id', loaded: false },
        { name: 'companies_rules', description: 'Unternehmens Regeln', index: '++id', loaded: false },
        { name: 'contracts_offered', description: 'Angebote', index: '++id', loaded: false },
        { name: 'contracts_offered_prices', description: 'Angebotspreise', index: '++id', loaded: false },
        { name: 'contracts_offered_rules', description: 'Angebotsregeln', index: '++id', loaded: false },
        { name: 'contracts_sales', description: 'Verkäufe', index: '++id', loaded: false },
        { name: 'contracts_sales_rules', description: 'Verkaufsregeln', index: '++id', loaded: false },
        { name: 'equipment_items', description: 'Trainingsgeräte', index: '++id', loaded: false },
        { name: 'equipment_kinds', description: 'Trainingsgerätearten', index: '++id', loaded: false },
        { name: 'finance_sales_invoices_outgoing', description: 'Ausgangsrechnungen', index: '++id', loaded: false },
        { name: 'finance_sales_invoices_outgoing_positions', description: 'Ausgangsrechnungenpositionen', index: '++id', loaded: false },
        { name: 'locations', description: 'Studios', index: '++id', loaded: false },
        { name: 'locations_openinghours', description: 'Öffnungszeiten', index: '++id', loaded: false },
        { name: 'locations_rooms', description: 'Studioräume', index: '++id', loaded: false },
        { name: 'media_files', description: 'Medien', index: '++id', loaded: false },
        { name: 'media_folders', description: 'Medienordner', index: '++id', loaded: false },
        { name: 'products', description: 'Produkte', index: '++id', loaded: false },
        { name: 'products_categories', description: 'Produkte Kategorien', index: '++id', loaded: false },
        { name: 'products_rules', description: 'Produkte Regeln', index: '++id', loaded: false },
        { name: 'resources', description: 'Mitarbeiter', index: '++id', loaded: false },
        { name: 'resources_calendar', description: 'Mitarbeiterkalender', index: '++id', loaded: false },
        { name: 'resources_companies', description: 'Unternehmensmitarbeiter', index: '++id', loaded: false },
        { name: 'rules', description: 'Regeln', index: '++id', loaded: false },
        { name: 'services', description: 'Dienstleistungen', index: '++id', loaded: false },
        { name: 'services_kindsof', description: 'Dienstleistungsarten', index: '++id', loaded: false },
        { name: 'services_rules', description: 'Dienstleistungsregeln', index: '++id', loaded: false },
        { name: 'tasks', description: 'Aufgaben', index: '++id', loaded: false },
        { name: 'tasks_dashboards', description: 'Aufgaben Dashboards', index: '++id', loaded: false },
        { name: 'user', description: 'Benutzer', index: '++id', loaded: false },
        { name: 'user_acl_level', description: 'Benutzerrechte', index: '++id', loaded: false },
        { name: 'user_acl_mapping', description: 'Benutzerrechte', index: '++id', loaded: false },
        { name: 'workouts_exercises', description: 'Workout Übungen', index: '++id', loaded: false },
        { name: 'workouts_program_templates', description: 'Workout Trainingsplan', index: '++id', loaded: false },
        { name: 'workouts_workout_templates', description: 'Workout Einheiten', index: '++id', loaded: false },
      ],
      Timezones: {
        "Pacific/Midway": "GMT-11:00 Pacific/Midway",
        "Pacific/Marquesas": "GMT-10:30 Pacific/Marquesas",
        "Pacific/Tahiti": "GMT-10:00 Pacific/Tahiti",
        "Pacific/Honolulu": "GMT-10:00 Pacific/Honolulu",
        "America/Sitka": "GMT-9:00 America/Sitka",
        "America/Juneau": "GMT-9:00 America/Juneau",
        "America/Anchorage": "GMT-9:00 America/Anchorage",
        "America/Vancouver": "GMT-8:00 America/Vancouver",
        "America/Tijuana": "GMT-8:00 America/Tijuana",
        "America/Metlakatla": "GMT-8:00 America/Metlakatla",
        "America/Los_Angeles": "GMT-8:00 America/Los Angeles",
        "America/Phoenix": "GMT-7:00 America/Phoenix",
        "America/Denver": "GMT-7:00 America/Denver",
        "America/Creston": "GMT-7:00 America/Creston",
        "America/Chihuahua": "GMT-7:00 America/Chihuahua",
        "Pacific/Galapagos": "GMT-6:00 Pacific/Galapagos",
        "America/North_Dakota/Center": "GMT-6:00 America/North Dakota/Center",
        "America/North_Dakota/Beulah": "GMT-6:00 America/North Dakota/Beulah",
        "America/Mexico_City": "GMT-6:00 America/Mexico City",
        "America/Costa_Rica": "GMT-6:00 America/Costa Rica",
        "America/Chicago": "GMT-6:00 America/Chicago",
        "America/Caracas": "GMT-5:30 America/Caracas",
        "America/Panama": "GMT-5:00 America/Panama",
        "America/New_York": "GMT-5:00 America/New York",
        "America/Lima": "GMT-5:00 America/Lima",
        "America/Jamaica": "GMT-5:00 America/Jamaica",
        "America/St_Johns": "GMT-4:30 America/St Johns",
        "Atlantic/Bermuda": "GMT-4:00 Atlantic/Bermuda",
        "America/Puerto_Rico": "GMT-4:00 America/Puerto Rico",
        "America/Lower_Princes": "GMT-4:00 America/Lower Princes",
        "America/Kralendijk": "GMT-4:00 America/Kralendijk",
        "America/Grenada": "GMT-4:00 America/Grenada",
        "Atlantic/Stanley": "GMT-3:00 Atlantic/Stanley",
        "America/Santiago": "GMT-3:00 America/Santiago",
        "Atlantic/South_Georgia": "GMT-2:00 Atlantic/South Georgia",
        "America/Sao_Paulo": "GMT-2:00 America/Sao Paulo",
        "America/Montevideo": "GMT-2:00 America/Montevideo",
        "Atlantic/Cape_Verde": "GMT-1:00 Atlantic/Cape Verde",
        "Atlantic/Azores": "GMT-1:00 Atlantic/Azores",
        "Atlantic/Canary": "GMT+0:00 Atlantic/Canary",
        "Atlantic/Reykjavik": "GMT+0:00 Atlantic/Reykjavik",
        "Europe/Dublin": "GMT+0:00 Europe/Dublin",
        "Europe/Lisbon": "GMT+0:00 Europe/Lisbon",
        "Europe/London": "GMT+0:00 Europe/London",
        "Europe/Amsterdam": "GMT+1:00 Europe/Amsterdam",
        "Europe/Berlin": "GMT+1:00 Europe/Berlin",
        "Europe/Brussels": "GMT+1:00 Europe/Brussels",
        "Europe/Busingen": "GMT+1:00 Europe/Busingen",
        "Europe/Madrid": "GMT+1:00 Europe/Madrid",
        "Europe/Paris": "GMT+1:00 Europe/Paris",
        "Europe/Rome": "GMT+1:00 Europe/Rome",
        "Europe/Stockholm": "GMT+1:00 Europe/Stockholm",
        "Europe/Vienna": "GMT+1:00 Europe/Vienna",
        "Europe/Zurich": "GMT+1:00 Europe/Zurich",
        "Asia/Hebron": "GMT+2:00 Asia/Hebron",
        "Asia/Jerusalem": "GMT+2:00 Asia/Jerusalem",
        "Europe/Athens": "GMT+2:00 Europe/Athens",
        "Europe/Helsinki": "GMT+2:00 Europe/Helsinki",
        "Europe/Istanbul": "GMT+2:00 Europe/Istanbul",
        "Europe/Kiev": "GMT+2:00 Europe/Kiev",
        "Asia/Baghdad": "GMT+3:00 Asia/Baghdad",
        "Asia/Riyadh": "GMT+3:00 Asia/Riyadh",
        "Europe/Minsk": "GMT+3:00 Europe/Minsk",
        "Europe/Moscow": "GMT+3:00 Europe/Moscow",
        "Indian/Comoro": "GMT+3:00 Indian/Comoro",
        "Asia/Tehran": "GMT+3:30 Asia/Tehran",
        "Asia/Dubai": "GMT+4:00 Asia/Dubai",
        "Asia/Tbilisi": "GMT+4:00 Asia/Tbilisi",
        "Indian/Mauritius": "GMT+4:00 Indian/Mauritius",
        "Asia/Kabul": "GMT+4:30 Asia/Kabul",
        "Asia/Karachi": "GMT+5:00 Asia/Karachi",
        "Asia/Samarkand": "GMT+5:00 Asia/Samarkand",
        "Asia/Yekaterinburg": "GMT+5:00 Asia/Yekaterinburg",
        "Indian/Kerguelen": "GMT+5:00 Indian/Kerguelen",
        "Asia/Colombo": "GMT+5:30 Asia/Colombo",
        "Asia/Kathmandu": "GMT+5:45 Asia/Kathmandu",
        "Asia/Almaty": "GMT+6:00 Asia/Almaty",
        "Asia/Dhaka": "GMT+6:00 Asia/Dhaka",
        "Asia/Omsk": "GMT+6:00 Asia/Omsk",
        "Indian/Chagos": "GMT+6:00 Indian/Chagos",
        "Asia/Rangoon": "GMT+6:30 Asia/Rangoon",
        "Asia/Bangkok": "GMT+7:00 Asia/Bangkok",
        "Asia/Ho_Chi_Minh": "GMT+7:00 Asia/Ho Chi Minh",
        "Asia/Jakarta": "GMT+7:00 Asia/Jakarta",
        "Indian/Christmas": "GMT+7:00 Indian/Christmas",
        "Asia/Brunei": "GMT+8:00 Asia/Brunei",
        "Asia/Chita": "GMT+8:00 Asia/Chita",
        "Asia/Hong_Kong": "GMT+8:00 Asia/Hong Kong",
        "Asia/Irkutsk": "GMT+8:00 Asia/Irkutsk",
        "Asia/Kuala_Lumpur": "GMT+8:00 Asia/Kuala Lumpur",
        "Asia/Manila": "GMT+8:00 Asia/Manila",
        "Asia/Shanghai": "GMT+8:00 Asia/Shanghai",
        "Asia/Singapore": "GMT+8:00 Asia/Singapore",
        "Asia/Taipei": "GMT+8:00 Asia/Taipei",
        "Asia/Ulaanbaatar": "GMT+8:00 Asia/Ulaanbaatar",
        "Australia/Perth": "GMT+8:00 Australia/Perth",
        "Australia/Eucla": "GMT+8:45 Australia/Eucla",
        "Asia/Khandyga": "GMT+9:00 Asia/Khandyga",
        "Asia/Pyongyang": "GMT+9:00 Asia/Pyongyang",
        "Asia/Seoul": "GMT+9:00 Asia/Seoul",
        "Asia/Tokyo": "GMT+9:00 Asia/Tokyo",
        "Asia/Yakutsk": "GMT+9:00 Asia/Yakutsk",
        "Pacific/Palau": "GMT+9:00 Pacific/Palau",
        "Australia/Darwin": "GMT+9:30 Australia/Darwin",
        "Asia/Ust-Nera": "GMT+10:00 Asia/Ust-Nera",
        "Asia/Vladivostok": "GMT+10:00 Asia/Vladivostok",
        "Australia/Brisbane": "GMT+10:00 Australia/Brisbane",
        "Pacific/Bougainville": "GMT+10:00 Pacific/Bougainville",
        "Pacific/Guam": "GMT+10:00 Pacific/Guam",
        "Australia/Adelaide": "GMT+10:30 Australia/Adelaide",
        "Asia/Srednekolymsk": "GMT+11:00 Asia/Srednekolymsk",
        "Australia/Melbourne": "GMT+11:00 Australia/Melbourne",
        "Australia/Sydney": "GMT+11:00 Australia/Sydney",
        "Pacific/Guadalcanal": "GMT+11:00 Pacific/Guadalcanal",
        "Asia/Kamchatka": "GMT+12:00 Asia/Kamchatka",
        "Pacific/Nauru": "GMT+12:00 Pacific/Nauru",
        "Pacific/Auckland": "GMT+13:00 Pacific/Auckland",
        "Pacific/Fiji": "GMT+13:00 Pacific/Fiji",
        "Pacific/Chatham": "GMT+13:45 Pacific/Chatham",
        "Pacific/Kiritimati": "GMT+14:00 Pacific/Kiritimati"
      }
    },
    drag: {
      daysdropzone: false,
      draggedelementdom: {
        drag: false,
        dom: [],
        dataob: []
      }
    },
    dev: {
      companies: '1515754c-a2c6-4293-bd45-0a4238406ba6,2c67995c-0690-4189-a010-67b71a9eb3c0'
    },
    DatabaseWorkerQueries: [],
    fileuploadqueue: [],
    integrations: [
      { name: 'Paypal', area: 'payment', img: '/img/paypal_logo.jpg', auth: {}},
      { name: 'Zoom.us', area: 'communication', img: '/img/zoom_logo.jpg', auth: {}}
    ],
    tables: {
      app_components: [],
      calendar_bookings: [],
      calendar_events: [],
      clients_info: [],
      clients_infoconfig: [],
      clients_mapping: [],
      clients_mapping_info: [],
      communication_accounts: [],
      communication_accounts_emailaddresses: [],
      communication_accounts_types: [],
      communication_outbox_internal_emails: [],
      companies: [],
      companies_connected: [],
      companies_acl_level: [],
      companies_acl_level_rights: [],
      companies_acl_mapping: [],
      companies_clients_groups: [],
      companies_healthstate: [],
      companies_pricerules: [],
      companies_rules: [],
      contracts_offered: [],
      contracts_offered_prices: [],
      contracts_offered_rules: [],
      contracts_sales: [],
      contracts_sales_rules: [],
      equipment_items: [],
      equipment_kinds: [],
      finance_sales_invoices_outgoing: [],
      finance_sales_invoices_outgoing_positions: [],
      locations: [],
      locations_openinghours: [],
      locations_rooms: [],
      media_files: [],
      media_folders: [],
      products: [],
      products_categories: [],
      products_rules: [],
      resources: [],
      resources_calendar: [],
      resources_companies: [],
      rules: [],
      services: [],
      services_kindsof: [],
      services_rules: [],
      tasks: [],
      tasks_dashboard: [],
      user: [],
      user_acl_level: [],
      user_acl_mapping: [],
      workouts_exercises: [],
      workouts_program_templates: [],
      workouts_workout_templates: [],
    },
    app_components: [],
    Clients: [],
    Clients_Info: [],
    Clients_Mapping: [],
    Clients_Mapping_Info: [],
    Clients_Infoconfig: [],
    Companies: [],
    companies_connected: [],
    Companies_HealthState: [],
    Companies_Pricerules: [],
    Companies_Clients_Groups: [],
    Calendar_events: [],
    Calendar_events_month_cache: [],
    Calendar_bookings: [],
    Calendar_bookings_month_cache: [],
    Calendar_bookings_by_activeclient: [],
    modaleventcontracts: [],
    modaleventbookings: [],
    communication_accounts: [],
    communication_accounts_types: [],
    communication_outbox_internal_emails: [],
    communication_messages: [],
    Equipment_KindsOf: [],
    Equipment_Items: [],
    EditorClient: '',
    EditorLocation: '',
    EditorStaff: '',
    Locations: [],
    Locations_Openinghours: [],
    Locations_rooms: [],
    Contracts_Offered: [],
    Contracts_Offered_Prices: [],
    Contracts_Offered_Rules: [],
    Contracts_Sales: [],
    Contracts_Sales_Rules: [],
    Contracts_Sales_Cart_Products: [],
    Contracts_Sales_Cart_Client: [],
    Finance_Sales_Invoices_Outgoing: [],
    Finance_Sales_Invoices_Outgoing_Positions: [],
    Notifications: [],
    Resources: [],
    Resources_Companies: [],
    Resources_Calendar: [],
    Rules: [],
    Services: [],
    Services_Rules: [],
    clients_infoconfig: [
      { id: '0ec8a829-0827-11e8-97f7-001132106cdd', infotype: 'stateofhealth', description: 'Gesundheitsstatus', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '0ec8ad2d-0827-11e8-97f7-001132106cdd', infotype: 'telefon', description: 'Telefon', valueparameter: '', category: 'clients', area: 'contactdata' },
      { id: '0ec8b2db-0827-11e8-97f7-001132106cdd', infotype: 'mobil', description: 'Mobile Rufnummer', valueparameter: '', category: 'clients', area: 'contactdata' },
      { id: '0ec8b7cc-0827-11e8-97f7-001132106cdd', infotype: 'profilimage', description: 'Profilbild', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '0ec8bc94-0827-11e8-97f7-001132106cdd', infotype: 'email', description: 'Emailadresse', valueparameter: '', category: 'clients', area: 'contactdata' },
      { id: '0ec8c165-0827-11e8-97f7-001132106cdd', infotype: 'social', description: 'Facebook/Twitter/LinkedIn', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '373df7ea-f13e-11e7-9321-001132106cdd', infotype: 'language', description: 'Bevorzugte Sprache', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '373df7ea-g13e-11e7-9321-001132106cdd', infotype: 'dateofbirth', description: 'Geburtstag', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '373df7ea-h13e-11e7-9321-001132106cdd', infotype: 'height', description: 'Größe', valueparameter: '', category: 'clients', area: 'personalinformation' },
      { id: '373df7ea-i13e-11e7-9321-001132106cdd', infotype: 'variable', description: 'Individueller Wert', valueparameter: '', category: 'clients', area: 'personalinformation' }
    ],
    images: [
      {
        id: '1',
        name: 'Fotolia_116966859_sabinehuerdler-1000x750.jpg',
        src: '../public/img/Fotolia_116966859_sabinehuerdler-1000x750.jpg',
        status: [
          { type: 'dbstored', value: false },
          { type: 'upload', value: false }
        ],
        type: 'image/jpeg',
        size: 1234,
        height: 1000,
        width: 750,
        dimensions: [
          {
            name: 'Portrait',
            coordinates: {
              left:'',
              top:'',
              width:'',
              height:''
            }
          },
          {
            name: 'Landscape',
            coordinates: {
              left:'',
              top:'',
              width:'',
              height:''
            }
          },
          {
            name: 'Square',
            coordinates: {
              left:'',
              top:'',
              width:'',
              height:''
            }
          },
        ],
        created: '',
        createdbyid: '',
        updated: '',
        updatedbyid: ''
      },
      {
        id: '2',
        name: 'Fotolia_116966859_sabinehuerdler-1000x750.jpg',
        src: '../public/img/Fotolia_116966859_sabinehuerdler-1000x750.jpg',
        type: 'image/jpeg',
        size: 1234,
        height: 1000,
        width: 750,
        status: [
          { type: 'dbstored', value: false },
          { type: 'upload', value: false }
        ],
        dimensions: [
          {
            name: 'Portrait',
            coordinates: {
              left:'',
              top:'',
              width:'',
              height:''
            }
          },
          {
            name: 'Landscape',
            coordinates: {
              left:'0%',
              top:'0%',
              width:'',
              height:''
            }
          },
          {
            name: 'Square',
            coordinates: {
              left:'',
              top:'',
              width:'',
              height:''
            }
          },
        ]
      },
    ],
    ServiceSpezification: [
      { id: 1, description: 'Monatlich', value: '1', dimension: 'time' },
      { id: 2, description: 'Vierteljährlich', value: '3', dimension: 'time' },
      { id: 3, description: 'Halbjährlich', value: '6', dimension: 'time' },
      { id: 4, description: 'Jährlich', value: '12', dimension: 'time' },
      { id: 5, description: '10er Karte', value: '10', dimension: 'quantity' },
      { id: 6, description: '20er Karte', value: '20', dimension: 'quantity' },
      { id: 8, description: '30er Karte', value: '30', dimension: 'quantity' },
      { id: 7, description: '50er Karte', value: '50', dimension: 'quantity' }
    ],
    Services_KindsOf: [],
    ScopeOfService: [
      { id: 1, description: 'Probetraining' },
      { id: 2, description: 'Personaltraining' },
      { id: 3, description: 'Partnertraining' },
      { id: 4, description: 'Gruppentraining' }
    ],
    Serverque: [],
    Todos: [],
    user: []
  },
  mutations: {
    setreportsyear: (state, data) => {
      state.config.reportsyear = data
    },
    calldatabaseserverworker: (state, data) => {
      console.log(data)
      databaseworkerservercom.postMessage({'cmd':'socketProxy','function': data.function, 'data': data.data })
    },
    calldatabasequeryworker: (state, data) => {
      console.log(data)
      // databaseworker.postMessage({'cmd': data.action,'data': data.data})
    },
    setpdfstatus: (state, data) => {
      state.pwa.pdfreceived = data
    },
    modalloadingstatus: (state, obj) => {
      state.modals.loadingstatus.show = obj.show
    },
    modaleditevent: (state, obj) => {
      state.modals.editevent.data = obj.dataobject
      state.modals.editevent.times = obj.times
      state.modals.editevent.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaladdcontract: (state, obj) => {
      state.modals.addcontract.data = obj.data
      state.modals.addcontract.show = obj.show
      state.modals.addcontract.type = obj.type
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaleditcontract: (state, obj) => {
      state.modals.editcontract.data = obj.data
      state.modals.editcontract.show = obj.show
      state.modals.editcontract.bookings = obj.bookings
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaleditroom: (state, obj) => {
      console.log(obj)
      state.modals.editroom.data = obj.dataobject
      state.modals.editroom.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaleditcontractoffering: (state, obj) => {
      console.log('modaleditcontractoffering')
      state.modals.editcontractoffering.data = obj.data
      state.modals.editcontractoffering.modus = obj.modus
      state.modals.editcontractoffering.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaleditimageratio: (state, obj) => {
      console.log('modaleditimageratio')
      console.log(obj)
      state.modals.editimageratio.data = obj.data
      state.modals.editimageratio.standardratios = obj.standardratios
      state.modals.editimageratio.name = obj.name
      state.modals.editimageratio.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modaleditimage: (state, obj) => {
      console.log('modaleditimage')
      console.log(obj)
      state.modals.editimage.data = obj.data
      state.modals.editimage.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modalpreviewmedia: (state, obj) => {
      console.log('modalmediapreview')
      console.log(obj)
      state.modals.mediapreview.data = obj.data
      state.modals.mediapreview.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modalmediabrowser: (state, obj) => {
      state.modals.mediabrowser.settings = obj.settings
      state.modals.mediabrowser.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modalduplicateevents: (state, obj) => {
      state.modals.duplicateevents.date = obj.date
      state.modals.duplicateevents.rooms = obj.rooms
      state.modals.duplicateevents.show = obj.show
      console.log(obj)
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    modalnewclient: (state, obj) => {
      state.modals.newclient.show = !state.modals.newclient.show
      if (state.modals.newclient.show === false) document.body.classList.remove('modal-open')
    },
    modaledittask: (state, obj) => {
      state.modals.edittask.modus = obj.modus
      state.modals.edittask.id = obj.id
      state.modals.edittask.show = obj.show
      if (obj.show === false) document.body.classList.remove('modal-open')
    },
    importemailmessages: (state, data) => {
      let message = {
        id: '',
        messagetype: '',
        typeid: '',
        datetime: '',
        threadid: '',
        labelids: '',
        to: '',
        from: '',
        subject: '',
        body: '',
        read: false
      }
      console.log(message)
    },
    importchatmessages: (state, data) => {
    },
    sethoverservice: (state, data) => {
      state.config.hoverservice = data
    },
    setClients: (state, data) => {
      state.Clients = data
    },
    setResources: (state, data) => {
      state.Resources = data
    },
    setLocations: (state, data) => {
      state.Locations = data
    },
    setEditorClient: (state, data) => {
      state.EditorClient = data
    },
    setEditorLocation: (state, data) => {
      state.EditorLocation = data
    },
    setEditorStaff: (state, data) => {
      state.EditorStaff = data
    },
    setServices_KindsOf: (state, data) => {
      state.KindOfServices = data
    },
    setContractSalesCartProducts: (state, data) => {
      state.Contracts_Sales_Cart_Products = data
    },
    setContractSalesCartClient: (state, data) => {
      state.Contracts_Sales_Cart_Client = data
    },
    setfilestouploadqueue: (state, data) => {
      console.log('setfilestouploadqueue')
      console.log(data)
      fileupload.postMessage({ cmd: 'newfiledata', file: data })
    },
    setactiveexerciseid: (state, data) => {
      state.config.ActiveExerciseId = data
    },
    setactiveworkoutid: (state, data) => {
      state.config.ActiveWorkoutId = data
    },
    setactiveprogramid: (state, data) => {
      state.config.ActiveProgramId = data
    },
    setworkoutoverviewactivetab: (state, data) => {
      state.config.workoutoverviewactivetab = data
    },
    setlasturlpath: (state, data) => {
      console.log('setlasturlpath',data)
      state.config.lasturlpath = data
    },
    addfileuploadqueue: (state, data) => {
      Vue.set(state.fileuploadqueue, state.fileuploadqueue.length, data)
    },
    updatefileuploadqueue: (state, data) => {
      let index = state.fileuploadqueue.findIndex(file => file.id === data.id)
      Vue.set(state.fileuploadqueue, index, data)
    },
    removefileuploadqueue: (state, data) => {
      let index = state.fileuploadqueue.findIndex(file => file.id === data.id)
      Vue.delete(state.fileuploadqueue, index)
      console.log(state.fileuploadqueue)
    },
    clearfileuploadqueue: (state) => {
      Vue.set(state, 'fileuploadqueue',[])
    },
    updateResourceDate: (state, data) => {
      Vue.set(state.ResourceDates, data.id - 1 , data)
    },
    addTodo: (state, todo) => {
      Vue.set(state.Todos, state.Todos.length, todo)
    },
    toggletodo: (state, data) => {
      let index = state.Todos.findIndex(todo => todo.id === data.id)
      Vue.set(state.Todos, index, data)
    },
    replaceimage: (state, data) => {
      let index = state.images.findIndex(image => image.id === data.id)
      Vue.set(state.images, index, data)
      console.log('index eingetragen:')
      console.log(index)
    },
    changeactivedate (state, data) {
      let date = new Date(state.dateconfig.ActiveDate)
      if (data.condition === 'm') {
        date.setMonth(date.getMonth() + data.parameter)
        Vue.set(state.dateconfig, 'ActiveDate', date.formatDate('YYYY-MM-DD'))
        Vue.set(state.dateconfig, 'ActiveMonth', date.getMonth())
        Vue.set(state.dateconfig, 'ActiveWeek', date.getWeekNumber())
        store.dispatch('refreshcalendarcaches2', date.formatDate('YYYY-MM-DD'))
      } else if (data.condition === 'w') {
        date.setDate(date.getDate() + (data.parameter * 7))
        Vue.set(state.dateconfig, 'ActiveDate', date.formatDate('YYYY-MM-DD'))
        if (state.dateconfig.ActiveMonth !== date.getMonth()) {
          Vue.set(state.dateconfig, 'ActiveMonth', date.getMonth())
          store.dispatch('refreshcalendarcaches2', date.formatDate('YYYY-MM-DD'))
        }
        Vue.set(state.dateconfig, 'ActiveWeek', date.getWeekNumber())
      } else if (data.condition === 'd') {
        date.setDate(date.getDate() + (data.parameter))
        Vue.set(state.dateconfig, 'ActiveDate', date.formatDate('YYYY-MM-DD'))
        if (state.dateconfig.ActiveMonth !== date.getMonth()) {
          Vue.set(state.dateconfig, 'ActiveMonth', date.getMonth())
          store.dispatch('refreshcalendarcaches2', date.formatDate('YYYY-MM-DD'))
        }
        Vue.set(state.dateconfig, 'ActiveWeek', date.getWeekNumber())
      }
      if (state.dateconfig.ActiveYear !== date.getFullYear()) {
        Vue.set(state.dateconfig, 'ActiveYear', date.getFullYear())
      }
      // if (store.state.dateconfig.ActiveMonth !== date.getMonth()) store.dispatch('refreshcalendarcaches2', date.formatDate('YYYY-MM-DD'))
      date = null
      return false
    },
    setactivedate (state, date) {
      let newdate = new Date(date)
      Vue.set(state.dateconfig, 'ActiveDate', newdate.formatDate('YYYY-MM-DD'))
      if (state.dateconfig.ActiveWeek !== newdate.getWeekNumber()) {
        Vue.set(state.dateconfig, 'ActiveWeek', newdate.getWeekNumber())
      }
      if (state.dateconfig.ActiveMonth !== newdate.getMonth()) {
        Vue.set(store.state.dateconfig, 'ActiveMonth', newdate.getMonth())
        store.dispatch('refreshcalendarcaches2', newdate)
      }
      if (state.dateconfig.ActiveYear !== newdate.getFullYear()) {
        Vue.set(state.dateconfig, 'ActiveYear', newdate.getFullYear())
      }
    },
    setdatabaseworkerquery (state, data) {
      Vue.set(state.DatabaseWorkerQueries, state.DatabaseWorkerQueries.length, data)
      // an DB Worker senden
    },
    setdbpwaloadingstatus (state, data) {
      Vue.set(state.pwa, 'dbloading', data)
    },
    setcalendarview (state, view) {
      Vue.set(state.dateconfig, 'CalendarView', view)
    },
    settimestepsinminutes (state, newValue) {
      Vue.set(state.dateconfig, 'ViewedTimeStepsInMinutes', newValue)
    },
    setshowstepsinminutes (state, newValue) {
      Vue.set(state.dateconfig, 'ShowTimeStepsInMinutes', newValue)
    },
    serverrefresh (state) {
      state.config.initalserverdatarequestdone = false
      state.config.initalworkerdatarequestdone = false
      Vue.set(state.pwa.dbloading, 'mode', 'update')
      Vue.set(state.pwa.dbloading, 'modus', 'serverrefresh')
      state.modals.loadingstatus.show = true
      databaseworkerservercom.postMessage({'cmd': 'serverrefresh'})
    },
    changeactiveclient: (state, id) => {
      Vue.set(state.config, 'ActiveClient', id)
      store.dispatch('getcalendarbookingsbyactiveclient')
    },
    backupdatabase: (state) => {
      databaseworkerservercom.postMessage({'cmd': 'backupdatabase'})
    },
    setlocationbyactivecompany: (state) => {
      console.log('setlocationbyactivecompany')
      if (state.config.ActiveCompany !== '' && state.config.ActiveLocation === '') {
        console.log(state.tables.locations)
        let locations = state.tables.locations.filter(location => location.companyid === state.config.ActiveCompany && location.archive === 0)
        if (locations.length > 0) Vue.set(state.config, 'ActiveLocation', locations[0].id)
        console.log(locations)
        console.log(state.config.ActiveLocation)
      }
    },
    changeactivecompany: (state, id) => {
      console.log('changeactivecompany')
      console.log(id)
      Vue.set(state.config, 'rightsloaded', false)
      store.commit('updateactiveviewsettings', { companyid: state.config.ActiveCompany, activelocation: state.config.ActiveLocation, activeroom: state.config.ActiveRoom })
      updateuseridbsettings('activesettingsperview')
      Vue.set(state.config, 'ActiveCompany', id)
      updateactivecompany(id)
      databaseworkerservercom.postMessage({'cmd':'socketProxy','function': 'changeactivecompany', 'data': id })
      store.commit('changeactiveclient', '')
      store.commit('setviewmode', 'studio')
      databaseworker.postMessage({'cmd': 'getuseracldata2', 'userid': state.Userdata.id, 'deviceid': state.Userdata.deviceid, 'activecompany': state.config.ActiveCompany})
      if (state.activesettingsperview[id]) {
        console.log('auch hier')
        console.log(state.activesettingsperview[id].activelocation)
        if (state.activesettingsperview[id].activelocation === '') {
          let locations = state.tables.locations.filter(location => location.companyid === id && !location.archive)
          console.log(locations)
          if (locations.length > 0) Vue.set(state.config, 'ActiveLocation', locations[0].id)
          locations = null
        } else {
          console.log('hier_ja')
          console.log(state.activesettingsperview[state.config.ActiveCompany].activelocation)
          Vue.set(state.config, 'ActiveLocation', state.activesettingsperview[id].activelocation)
        }
        if (state.activesettingsperview[state.config.ActiveCompany].activeroom === '') {
          let rooms = state.tables.locations_rooms.filter(room => room.locationid === state.config.ActiveLocation && room.archive === 0)
          if (rooms.length > 0) Vue.set(state.config, 'ActiveRoom', rooms[0].id)
          rooms = null
        } else {
          Vue.set(state.config, 'ActiveRoom', state.activesettingsperview[state.config.ActiveCompany].activeroom)
        }
        router.push(state.activesettingsperview[state.config.ActiveCompany].activeroute)
      } else {
        let locations = state.tables.locations.filter(location => location.companyid === id && location.archive === 0)
        if (locations.length > 0) Vue.set(state.config, 'ActiveLocation', locations[0].id)
        let rooms = state.tables.locations_rooms.filter(room => room.locationid === locations[0].id && room.archive === 0)
        if (rooms.length > 0) Vue.set(state.config, 'ActiveRoom', rooms[0].id)
        locations = null
        rooms = null
      }
    },
    changeactivelocation: (state, id) => {
      Vue.set(state.config, 'ActiveLocation', id)
      console.log(state.config.ActiveLocation)
      if (state.activesettingsperview[state.config.ActiveCompany]) {
        state.activesettingsperview[state.config.ActiveCompany].activelocation = id
        let companysettings = state.activesettingsperview[state.config.ActiveCompany]
        if (companysettings.locations[id] && companysettings.locations[id][0].activeroomid !== '') {
          Vue.set(state.config, 'ActiveRoom', companysettings.locations[id][0].activeroomid)
        } else {
          let rooms = state.tables.locations_rooms.filter(room => room.locationid === id && room.archive === 0)
          if (rooms.length > 0) {
            Vue.set(state.config, 'ActiveRoom', rooms[0].id)
          } else {
            console.log('Keine Räume vorhanden')
          }
          rooms = null
        }
      }
      return false
    },
    changeactiveroom: (state, id) => {
      console.log('changeactiveroom')
      Vue.set(state.config, 'ActiveRoom', id)
      let companysettings = state.activesettingsperview[state.config.ActiveCompany]
      if (typeof companysetting !== 'undefined') {
        companysettings.locations[state.config.ActiveLocation][0].activeroomid = id
        state.activesettingsperview[state.config.ActiveCompany].activelocation = state.config.ActiveLocation
        let companysetting = state.activesettingsperview[state.config.ActiveCompany]
        if (typeof companysetting[state.config.ActiveLocation] !== 'undefined') {
          companysetting[state.config.ActiveLocation].activeroom = id
        } else {
          companysetting[state.config.ActiveLocation] = {}
          companysetting[state.config.ActiveLocation].activeroom = id
        }
      }
      companysettings = null
    },
    setviewmode (state, mode) {
      Vue.set(state.config, 'CalenderViewMode', mode)
    },
    setdraggedelement (state, data) {
      Vue.set(state.drag, 'draggedelement', data)
    },
    setdaysdropzone (state, data) {
      Vue.set(state.drag, 'daysdropzone', data)
    },
    setcelldimensionsweek (state, data) {
      Vue.set(state.dateconfig, 'cellheight', data.height)
      Vue.set(state.dateconfig, 'cellwidth', data.width)
    },
    sizechanged (state, data) {
      Vue.set(state.dateconfig, 'sizechanged', data)
    },
    setresolutionmode (state, data) {
      Vue.set(state.config, 'ResolutionMode', data)
      if (data === 'desktop' && state.dateconfig.sizechanged === '') {
        if (document.body.classList.contains('aside-menu-hidden')) {
          document.body.classList.toggle('aside-menu-hidden')
        }
        if (document.body.classList.contains('sidebar-hidden')) {
          document.body.classList.toggle('sidebar-hidden')
        }
      }
      console.log('ResolutionMode: ' + data)
      Vue.set(state.dateconfig, 'sizechanged', new Date())
    },
    setcelldimensionsheight (state, data) {
      Vue.set(state.dateconfig, 'cellheight', data)
    },
    setShowResources (state, data) {
      Vue.set(state.dateconfig, 'ShowResources', data)
    },
    setshowarchivedtrainings (state, data) {
      Vue.set(state.dateconfig, 'showarchivedtrainings', data)
    },
    setcelldimensionsday (state, data) {
      Vue.set(state.dateconfig.day, 'cellheight', data.height)
      Vue.set(state.dateconfig.day, 'cellwidth', data.width)
    },
    accesstoken_request (state) {
      console.log('main accesstoken_request')
      let uri = ''
      if (process.env.NODE_ENV === 'development') {
        uri = '192.168.3.22:8085/auth/signin/login/'
        // uri = 'http://192.168.3.22:8085/auth/signin/accesstoken'
      } else if (process.env.NODE_ENV === 'production') {
        uri = 'https://sppapi2.mycrogym.com/auth/signin/accesstoken/'
      }
      checkdeviceid({id: state.Userdata.id})
        .then(function (result) {
          console.log(result)
          if (result) {
            let credentials = {uid: result.id, did: result.di, rt: result.rt}
            console.log(credentials)
            Vue.http.post(uri, JSON.stringify(credentials))
              .then(function (response) {
                // get Authentication Code
                console.log(response)
                if (response || response.body.at || response.body.rt) {
                  console.log('Got new access token')
                  console.log(response)
                  updateuseridbsettings('tokens', {at: response.body.at, rt: response.body.rt})
                  databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': response.body.at, 'userid': state.Userdata.id, 'deviceid:': result.di, 'refresh': state.config.refreshdatabase, 'activecompany': result.ac})
                  Vue.set(state.config, 'token', response.body.accessToken)
                  // if (socket.connected === false) store.commit('connect_socket')
                } else {
                  // Hier Code wenn keine Verbindung hergestellt wurde
                }
              })
          }
        })
    },
    http_authentication (state, data) {
      console.log('http_authentication')
      Vue.set(state.config, 'passwordwrong', false)
      let uri = ''
      if (process.env.NODE_ENV === 'development') {
        uri = 'http://192.168.3.22:8085/auth/signin/login/'
        // uri = 'http://192.168.3.22:8085/auth/signin/login/'
      } else if (process.env.NODE_ENV === 'production') {
        uri = 'https://sppapi2.mycrogym.com/auth/signin/login/'
      }
      let newdata = {}
      console.log(data)
      newdata.email = data.email
      newdata.password = data.password
      newdata.deviceinfo = data.deviceinfo
      newdata.publickey = data.publickey
      fetch(uri, {
        method: 'POST',
        body: JSON.stringify(newdata),
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'access-control-allow-origin' : '*'
        }
      })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText)
                Vue.set(state.config, 'passwordwrong', true)
                Vue.set(state.config, 'authretries', state.config.authretries + 1)
                Vue.set(state.config, 'isLoading', false)
                Vue.set(state.config, 'waitingforresponse', false)
            }
            return response
        })
        .then(function(response) { return response.json() })
        .then(function (response) {
          databaseworker.postMessage({'cmd': 'init'})
          Vue.set(state.config, 'authretries', 0)
          Vue.set(state.config, 'isLoggedin', true)
          Vue.set(state.config, 'isAuthenticated', true)
          Vue.set(state.config, 'token', response.token)
          data.userid = response.userid
          data.token = response.token
          data.rtoken = response.rtoken
          data.deviceid = response.deviceid
          data.serverpublickey = response.pkey
          console.log(response.pkey)
          store.state.config.deviceid = response.deviceid
          data.companies = JSON.parse(response.companies)
          // if (state.config.ActiveCompany === "") Vue.set(state.config, 'ActiveCompany', data.companies[0])
          if (!store.state.Userdata.id) {
            Vue.set(state.Userdata, 'id', data.userid)
            Vue.set(state.Userdata, 'companies', data.companies)
          }
          databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': data.token, 'userid': data.userid, 'deviceid': data.deviceid, 'refresh': state.config.refreshdatabase, 'activecompany': data.companies[0]})
          databaseworkerservercom.postMessage({'cmd': 'getinitialdata', 'userid': data.userid})
          if (store.state.config.lasturlpath === '') {
            router.push('/dashboard')
          } else {
            router.push(store.state.config.lasturlpath)
          }
          checkifcompaniesindb(data.companies)
            .then(function (result) {
              if (data.userinstorage) {
                if (state.config.initalserverdatarequestdone === false) init(data)
                updateuseridbsettings('tokens', {at: data.token, rt: data.rtoken})
                // databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': data.token, 'userid': data.userid, 'deviceid': data.deviceid, 'refresh': state.config.refreshdatabase, 'activecompany': data.companies[0]})
                // databaseworkerservercom.postMessage({'cmd': 'getinitialdata', 'userid': data.userid})
                hashPassword(data)
                  .then(function (password) {
                    updateexistinguser(data,password)
                    .then(function () {
                      state.config.initalserverdatarequestdone = false
                      init(data)
                      // databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': data.token, 'userid': data.userid, 'deviceid': data.deviceid, 'refresh': state.config.refreshdatabase, 'activecompany': data.companies[0]})
                      // databaseworkerservercom.postMessage({'cmd': 'getinitialdata', 'userid': data.userid})
                      Vue.set(state.config, 'waitingforresponse', false)
                    })
                  })
              } else {
                hashPassword(data)
                  .then(function (password) {
                    insertnewuser(data, password)
                      .then(function () {
                        state.config.initalserverdatarequestdone = false
                        init(data)
                        // databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': data.token, 'userid': data.userid, 'deviceid': data.deviceid, 'refresh': state.config.refreshdatabase, 'activecompany': data.companies[0]})
                        // databaseworkerservercom.postMessage({'cmd': 'getinitialdata', 'userid': data.userid})
                        Vue.set(state.config, 'waitingforresponse', false)
                      })
                  })
              }
            })
          })
        .catch(function(error) {
          console.log(error)
          Vue.set(state.config, 'passwordwrong', true)
          Vue.set(state.config, 'authretries', state.config.authretries + 1)
          Vue.set(state.config, 'isLoading', false)
          Vue.set(state.config, 'waitingforresponse', false)
        })
      newdata = null
    },
    loginprocess (state, data) {
      console.log(data)
      console.log('Loginprozess')
      Vue.set(state.config, 'waitingforresponse', true)
      Vue.set(state.config, 'isLoading', true)
      initiatemgconfig()
      checkdeviceid(data)
      checkfordbversion().then(function (db) {
        console.log(db)
        checkifuserisinstorage(data)
          .then(function (userdata) {
            console.log(userdata)
            data.userinstorage = false
            if (userdata && userdata.id) {
              if (supportsCrypto()) {
                hash('SHA-256', data.password).then(hashed => {
                  console.log('Passwortmatch:')
                  console.log(encode64(hashed) === userdata.pw)
                  if (encode64(hashed) === userdata.pw) {
                    databaseworker.postMessage({'cmd': 'init'})
                    Vue.set(state.config, 'authretries', 0)
                    Vue.set(state.config, 'waitingforresponse', false)
                    init(userdata)
                    Vue.set(state.config, 'isLoggedin', true)
                    if (state.config.lasturlpath === '') {
                      router.push('/dashboard')
                    } else {
                      router.push(state.config.lasturlpath)
                    }
                    Vue.set(state.config, 'isAuthenticated', true)
                    Vue.set(state.config, 'ActiveCompany', userdata.ac)
                    console.log('company',userdata.ac)
                    Vue.set(state.config, 'token', userdata.at)
                    Vue.set(state.Userdata, 'id', userdata.id)
                    data.userinstorage = true
                    Vue.set(state.Userdata, 'companies', userdata.cs)
                    databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': userdata.at, 'userid': userdata.id, 'deviceid': userdata.di, 'refresh': state.config.refreshdatabase, 'activecompany': userdata.ac})
                    // databaseworker.postMessage({'cmd': 'getuseracldata2', 'userid': userdata.id, 'deviceid': userdata.di, 'activecompany': userdata.ac})
                    // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
                    // store.dispatch('refreshcalendarcaches2', store.state.dateconfig.ActiveDate)
                  } else {
                    // this.$root.$children[0].$refs.toastr.w("Login fehlgeschlagen", "Error")
                    store.commit('http_authentication', data)
                    Vue.set(state.config, 'passwordwrong', true)
                    Vue.set(state.config, 'authretries', state.config.authretries + 1)
                    Vue.set(state.config, 'isLoggedin', false)
                    Vue.set(state.config, 'isAuthenticated', false)
                    Vue.set(state.config, 'waitingforresponse', false)
                    store.commit('http_authentication', data)
                  }
                })
              } else {
                bcrypt.compare(data.password, userdata.pw, function (err2, res2) {
                  if (err2) {
                    console.log(err2)
                    Vue.set(state.config, 'passwordwrong', true)
                    Vue.set(state.config, 'authretries', state.config.authretries + 1)
                  }
                  if (res2) {
                    console.log('loginprocess - Password OK 2')
                    Vue.set(state.config, 'authretries', 0)
                    databaseworker.postMessage({'cmd': 'init'})
                    Vue.set(state.config, 'isLoggedin', true)
                    if (state.config.lasturlpath === '') {
                      router.push('/dashboard')
                    } else {
                      router.push(state.config.lasturlpath)
                    }
                    if (state.config.initalserverdatarequestdone === false) init(userdata)
                    Vue.set(state.config, 'token', userdata.at)
                    databaseworkerservercom.postMessage({'cmd': 'settoken', 'token': userdata.at, 'userid': userdata.id, 'refresh': state.config.refreshdatabase, 'activecompany': userdata.ac})
                    // databaseworker.postMessage({'cmd': 'getuseracldata2', 'userid': userdata.id, 'deviceid': userdata.di, 'activecompany': userdata.ac})
                    Vue.set(state.Userdata, 'id', userdata.id)
                    Vue.set(state.Userdata, 'companies', userdata.cs)
                    Vue.set(state.config, 'isAuthenticated', true)
                    Vue.set(state.config, 'ActiveCompany', userdata.ac)
                    // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
                    console.log('company2',userdata.ac)
                    data.userinstorage = true
                    if (store.state.config.initalserverdatarequestdone === false) {
                      console.log('Hier')
                      store.commit('http_authentication', data)
                    }
                  } else {
                    console.log('Login fehlgeschlagen')
                    Vue.set(state.config, 'isLoggedin', false)
                    Vue.set(state.config, 'isAuthenticated', false)
                    Vue.set(state.config, 'waitingforresponse', false)
                    store.commit('http_authentication', data)
                  }
                })
              }
            } else {
              console.log('Nicht im Speicher vorhanden')
              data.deviceinfo = {
                id: '',
                appCodeName: navigator.appCodeName,
                appName: navigator.appName,
                userAgent: navigator.userAgent,
                platform: navigator.platform
              }
              console.log(data.deviceinfo)
              data.privatekey = ''
              data.publickey = ''
              window.crypto.subtle.generateKey(
                  {
                    name: "RSA-OAEP",
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                    hash: {name: "SHA-256"}
                  },
                  false,
                  ["encrypt", "decrypt"] // must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
                )
                .then(function (keypair) {
                  window.crypto.subtle.exportKey("spki", keypair.publicKey)
                    .then(function (keypub) {
                      let exportedAsString = ab2str(keypub)
                      let exportedAsBase64 = window.btoa(exportedAsString)
                      data.privatekeystore = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`
                    })
                  // data.privatekeystore = keypair.privateKey
                  window.crypto.subtle.exportKey("spki", keypair.publicKey)
                    .then(function (keypub) {
                      let exportedAsString = ab2str(keypub)
                      // data.publickey = window.btoa(exportedAsString)
                      data.publickey = exportedAsString
                      store.commit('http_authentication', data)
                      exportedAsString = null
                    })
                })
              }
          })
      })
    },
    logoutbyuser (state, data) {
      router.push('/login')
      databaseworkerservercom.postMessage({'cmd':'userlogout'})
      Vue.set(state.config, 'loggedoutbyuser', data)
      Vue.set(state.config, 'isLoggedin', false)
      Vue.set(state.config, 'isConnected', false)
      Vue.set(state.config, 'isAuthenticated', false)
      Vue.set(state.config, 'isLoading', false)
      Vue.set(state.config, 'rightsloaded', false)
      Vue.set(state.Userdata, 'id', '')
      Vue.set(state.Userdata, 'credentials', {})
      Vue.set(state.Userdata, 'datasource', {})
      Vue.set(state.Userdata, 'useracls', {})
      Vue.set(state.Userdata, 'userrightsbycompany', {})
      Vue.set(state.Userdata, 'UserCompaniesIds', "")
      Vue.set(state.Userdata, 'CompaniesResources', [])
      Vue.set(state.Userdata, 'ActiveCompanyResource', {})
      Vue.set(state.config, 'ActiveUserCompanyACLLevel', [])
      Vue.set(state.config, 'ActiveUserCompanyACLRights', [])
      Vue.set(state.config.month_cache,'initialcacheupdatedone', false)
      Vue.set(state.Userdata, 'datasource', data)
      console.clear()
    },
    updateactivesettings (state) {
      getidbuserdata()
        .then(function (result) {
          console.log(result)
          if (result && result.vs !== "") {
            state.activesettingsperview = Object.assign([],result.vs)
            console.log(state.activesettingsperview)
            if (state.activesettingsperview[state.config.ActiveCompany]) Vue.set(state.config,'ActiveLocation',state.activesettingsperview[state.config.ActiveCompany].activelocation)
          } else {
            for (let i = 0; i < state.tables.companies.length; i++) {
              let companyid = state.tables.companies[i].id
              if (!state.activesettingsperview[companyid]) {
                state.activesettingsperview[companyid] = []
                state.activesettingsperview[companyid].activeroute = ''
                state.activesettingsperview[companyid].activelocation = ''
                state.activesettingsperview[companyid].activeroom = ''
                state.activesettingsperview[companyid].locations = []
                state.activesettingsperview[companyid].clientlistsettings = {
                  groups: [],
                  status: [],
                  locations: [],
                  scrollpositiontop: 0,
                  searchquery: ''
                }
              }
              let activecompanysettings = state.activesettingsperview[companyid]
              if (state.tables.locations.length > 0) {
                let locationspercompany = Object.assign([],state.tables.locations.filter(location => location.companyid === companyid && !location.archive))
                for (let i2 = 0; i2 < locationspercompany.length; i2++) {
                  let locationid = locationspercompany[i2].id
                  if (activecompanysettings.locations[locationid]) {
                    activecompanysettings.locations[locationid] = []
                    activecompanysettings.locations[locationid].push({activeroomid: ''})
                  }
                }
                locationspercompany = null
              }
            }
          }
        })
      return false
    },
    updateactiveviewsettingscalendar (state, data) {
      console.log('updateactiveviewsettingscalendar')
      console.log(state.activesettingsperview[state.config.ActiveCompany])
      if (state.activesettingsperview[state.config.ActiveCompany]) {
        state.activesettingsperview[state.config.ActiveCompany].calendarcellheight = Number(data.data.cellheight)
        state.activesettingsperview[state.config.ActiveCompany].calendardaystoshow = Number(data.data.daystoshow)
        state.activesettingsperview[state.config.ActiveCompany].calendarselecteddays = data.data.selecteddays
        state.activesettingsperview[state.config.ActiveCompany].calendartimestepsinminutes = Number(data.data.timestepsinminutes)
        state.activesettingsperview[state.config.ActiveCompany].calendarshowarchivedtrainings = state.dateconfig.showarchivedtrainings
        state.activesettingsperview[state.config.ActiveCompany].calendarshowresources = state.dateconfig.ShowResources
        updateuseridbsettings('activesettingsperview')
      } else {
        store.commit("updateactivesettings")
      }
      return false
    },
    updateactiveviewsettings (state, data) {
      if (state.activesettingsperview[data.companyid]) {
        state.activesettingsperview[data.companyid].activelocation = data.activelocation
        state.activesettingsperview[data.companyid].activeroom = data.activeroom
        state.activesettingsperview[data.companyid].activeroute = router.history.current.path
        updateuseridbsettings('activesettingsperview')
      }
      return false
    },
    updateactiveviewsettingsrouterpath (state, data) {
      if (state.activesettingsperview[data.companyid]) state.activesettingsperview[data.companyid].activeroute = router.history.current.path
      console.log(router.history.current.path)
    },
    updateactiveviewsettingsclientlistscrollLtop (state, data) {
      if (state.activesettingsperview[data.company]) state.activesettingsperview[data.company].clientlistsettings.scrollpositiontop = data.data
    },
    updateactiveviewsettingsclientlistgroups (state, data) {
      if (state.activesettingsperview[data.company]) state.activesettingsperview[data.company].clientlistsettings.groups = data.data
    },
    updateactiveviewsettingsclientlistlocations (state, data) {
      if (state.activesettingsperview[data.company]) state.activesettingsperview[data.company].clientlistsettings.locations = data.data
    },
    updateactiveviewsettingsclientliststatus (state, data) {
      if (state.activesettingsperview[data.company]) state.activesettingsperview[data.company].clientlistsettings.status = data.data
    },
    updateactiveviewsettingsclientlistsearchquery (state, data) {
      if (state.activesettingsperview[data.company]) state.activesettingsperview[data.company].clientlistsettings.searchquery = data.data
      updateuseridbsettings('activesettingsperview')
    },
    refreshFromIndexedDB (state,data) {
      store.state.config.month_cache.initialcacheupdatedone = false
      store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
      return false
    }
  },
  getters: {
    getOpenNotifications: function (state) {
      return state.Notifications.filter(Note => Note.status === 'open' && Note.popup === true)
    },
    // getContractData: (state) => (contractid) => {
      // auf Action umbauen
    //  let contract = {}
    //  if (contractid !== '') {
    //      contract.data = {}
    //      contract.data = Object.assign([],state.tables.contracts_sales.filter(contract => contract.id === contractid))[0]
    //      contract.client = {}
    //      if (typeof contract.data !== 'undefined') {
    //        contract.client = Object.assign({},state.tables.clients_mapping.find(client => client.id === contract.data.clientid))
    //        contract.company = {}
    //        contract.company = Object.assign([],state.tables.companies.filter(company => company.id === contract.data.companyid))[0]
    //        contract.rules = []
    //        contract.rules = state.Contracts_Sales_Rules.filter(rule => rule.contractid === contractid)
    //      }
    //    }
    //    return contract
    //  },
    // getContractsSalesbyClient: (state) => {
      // auf State umbauen
    //  return state.Contracts_Sales.filter(contract => contract.clientid === state.config.ActiveClient && contract.servicedimension === '1' && contract.archive === 0)
    // },
    // getcontract: (state, getters) => (id) => {
      // auf State umbauen
    //  if (state.Contracts_Offered.length) return state.Contracts_Offered.find(oc => oc.id === id)
    // },
    getservices: state => {
      // auf State umbauen
      return state.tables.services.filter(service => service.companyid === state.config.ActiveCompany)
    },
    getActiveCompanyData: state => {
      // auf State umbauen
      return state.tables.companies.find(company => company.id === state.config.ActiveCompany)
    },
    getCompanyHealthStates: state => {
      // auf State umbauen
      return state.tables.companies_healthstate.filter(item => item.companyid === state.config.ActiveCompany)
    },
    getConnectedCompanies: state => {
      // auf State umbauen
      return state.tables.companies.filter(company => company.id !== state.config.ActiveCompany)
    },
    getLocationsbyActiveCompany: state => {
      let locationacl = []
      let result = []
      let acls = store.state.config.ActiveUserCompanyACLRights.find(acl => acl.action === 'locations')
      console.log(store.state.config.ActiveUserCompanyACLRights)
      if (acls) {
        console.log('ActiveUserCompanyACLRights not undefined')
        locationacl = acls['val'].split(",")
        console.log(locationacl)
        result = state.tables.locations.filter(location => location.companyid === state.config.ActiveCompany && locationacl.includes(location.id))
      } else {
        console.log('ActiveUserCompanyACLRights undefined')
        result = state.tables.locations.filter(location => location.companyid === state.config.ActiveCompany)
      }
      return result
    },
    getRoomsbyActiveLocation: state => {
      return state.tables.locations_rooms.filter(room => room.locationid === state.config.ActiveLocation && room.archive === 0)
    },
    getActiveLocation: state => {
      return state.tables.locations.find(location => location.id === state.config.ActiveLocation)
    },
    getKindsOfServices: state => {
      return state.tables.services_kindsof.filter(service => service.companyid === state.config.ActiveCompany)
    },
    getEquipmentItemsbyCompany: state => {
      let result = []
      for (let i = 0; i < state.tables.equipment_kinds.length; i++) {
        if (state.tables.equipment_kinds[i].companyid === state.config.ActiveCompany) {
          let items = state.tables.equipment_items.filter(item => item.kindofequipmentid === state.tables.equipment_kinds[i].id)
          if (items) {
            for (let i2 = 0; i2 < items.length; i2++) {
              result.push(items[i2])
            }
          }
          items = null
        }
      }
      return result
    },
    resources: state => {
      // auf State umbauen
      return state.tables.resources.filter(resource => resource.archive === 0)
    },
    getacllistbycompany: state => {
      return state.Acl.levels_companies.filter(resource => resource.companyid === state.config.ActiveCompany)
    },
    getresourcesbycompany: state => {
      let resourcesbycomp = state.tables.resources_companies.filter(resource => resource.companyid === state.config.ActiveCompany)
      if (typeof resourcesbycomp !== 'undefined') {
        return resourcesbycomp
      } else {
        return {}
      }
    },
    getlocationsbycompany: state => {
      let locationsbycomp = state.tables.locations.filter(location => location.companyid === state.config.ActiveCompany)
      return locationsbycomp
    },
    getLocationsOpeninghours: (state, getters) => {
      return state.tables.locations_openinghours
    },
    getclientsmappinginfo: (state, getters) => {
      return state.tables.clients_mapping_info.filter(info => info.clientmappingid === state.config.ActiveClient)
    },
    getClientsByCompany: (state, getters) => {
      if (state.tables.clients_mapping && state.tables.clients_mapping.length > 0) {
        return state.tables.clients_mapping.filter(client => client.companyid === state.config.ActiveCompany)
      } else {
        return [{firstname: 'Keine', surname: 'Kunden vorhanden'}]
      }
    },
    getinvoicesoutgoingbyclient: (state, getters) => {
      return state.tables.finance_sales_invoices_outgoing.filter(invoice => invoice.companyid === state.config.ActiveCompany && invoice.clientid === state.config.ActiveClient)
    },
    getinvoicesoutgoingbycompany: (state, getters) => {
      return state.tables.finance_sales_invoices_outgoing.filter(invoice => invoice.companyid === state.config.ActiveCompany)
    },
    getinvoicesoutgoingpositionsbyclient: (state, getters) => {
      return state.tables.finance_sales_invoices_outgoing_positions.filter(invoice => invoice.clientid === state.config.ActiveClient)
    }
  },
  actions: {
    // setaclrightsbyacllevel: () => {
    //   console.log('setaclrightsbyacllevel')
    //   getUserdata()
    //     .then(function (userdata) {
    //       console.log(userdata)
    //       Vue.set(store.state.Userdata, 'datasource', userdata)
    //       getGlobalUserResourceresourcebyuserid()
    //         .then(function (resource) {
    //           getCompanyResourcesByGlobalResourceID(resource)
    //             .then(function (companiesresources) {
    //               let activeresource = companiesresources.find(companyresource => companyresource.companyid === store.state.config.ActiveCompany)
    //               Vue.set(store.state.Userdata, 'UserCompaniesIds', companiesresources.map(item => (item.id)))
    //               Vue.set(store.state.Userdata, 'CompaniesResources', Object.assign([],companiesresources))
    //               Vue.set(store.state.Userdata, 'ActiveCompanyResource', Object.assign({},activeresource))
    //               activeresource = null
    //               getCompanyResourceACLLevel(companiesresources)
    //                 .then(function (result) {
    //                   console.log(result)
    //                   Vue.set(store.state.config, 'ActiveUserCompanyACLLevel', result)
    //                   getActiveCompanyResourceACLLevelRights(result)
    //                     .then(function (rights) {
    //                       console.log(rights)
    //                       Vue.set(store.state.config, 'ActiveUserCompanyACLRights', rights)
    //                       Vue.set(store.state.config, 'rightsloaded', true)
    //                       Vue.set(store.state.config, 'isLoading', false)
    //                       console.log('Rechte in MAIN geladen')
    //                     store.commit('connect_socket')
    //                     })
    //                 })
    //             })
    //         })
    //     })
    //   return true
    // },
    createPDF: ({ commit, state }, data) => {
      generalworker.postMessage({'cmd': 'getPdf', 'msg': JSON.stringify(data)})
    },
    createPDFBase64: ({ commit, state }, data) => {
      generalworker.postMessage({'cmd': 'getPdfBase64', 'msg': JSON.stringify(data)})
    },
    setactivecompany: (state, companyid) => {
      store.state.config.isLoading = true
      store.commit('changeactivecompany', companyid)
    },
    authenticationthroughlocalstorage: (state) => {
      // socket.emit('authentication','geht')
    },
    getinitialdata: (state, UserId) => {
      // socket.emit('sendinitialdata', UserId)
    },
    getcalendarbookingsbyactiveclient () {
      store.state.Calendar_bookings_by_activeclient = []
      if (store.state.config.ActiveClient) databaseworker.postMessage({'cmd': 'getcalendarbookingsbyactiveclient','activeclient': store.state.config.ActiveClient})
    },
    getinternalemailsbyactiveclient () {
      if (store.state.config.ActiveClient !== '' && store.state.config.ActiveClient !== null) databaseworker.postMessage({'cmd': 'getinternalemailsbyactiveclient','activeclient': store.state.config.ActiveClient})
    },
    getmodalcontractsandbookings: (state, serviceid) => {
      databaseworker.postMessage({'cmd': 'getmodalcontractsandbookings','serviceid': serviceid, 'companyid': store.state.config.ActiveCompany})
    },
    customrequeststodatabase: (state, config) => {
      // To queue
      store.dispatch('dbqueue/addJob', {
        id: config.id,
        function: config.function,
        config: config
      })
      // To worker
      // databaseworker.postMessage({'cmd': config.function,'config': config })
    },
    customrequestdelete: (state, id) => {
      const i = store.state.customrequests.map(item => item.id).indexOf(id)
      store.state.customrequests.splice(i, 1)
      return false
    },
    calldatabaseworker: (state, config) => {
      console.log(config)
      databaseworker.postMessage({'cmd': config.function,'config': config.config })
    },
    refreshcalendarcaches ({commit, dispatch}, newdate, companies) {
      console.log('refreshcalendarcaches')
      let date = new Date(newdate)
      console.log(store.state.config.month_cache.initialcacheupdatedone)
      store.state.config.CacheisLoading = true
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0)
      let dataobject = {
        year: date.getFullYear(),
        month: date.getMonth(),
        start: firstDay.formatDate("YYYY-MM-DD"),
        end: lastDay.formatDate("YYYY-MM-DD"),
        companies: store.state.config.ActiveCompany
      }
      console.log(dataobject)
      // store.dispatch('dbqueue/addJob', {
      //   id: uid(),
      //   function: 'getcache',
      //   description: 'Daten laden',
      //   data: dataobject
      // })
      databaseworker.postMessage({'cmd': 'getcache','query': dataobject})
      Vue.set(store.state.config.month_cache, 'start', firstDay.formatDate("YYYY-MM-DD"))
      Vue.set(store.state.config.month_cache, 'end', lastDay.formatDate("YYYY-MM-DD"))
      Vue.set(store.state.config.month_cache, 'month', date.getMonth())
      dataobject = null
      firstDay = null
      lastDay = null
      date = null
    },
    refreshcalendarcaches2 ({commit, dispatch}, newdate, companies) {
      console.log('refreshcalendarcaches2')
      console.log(newdate)
      store.state.config.eventsloaded = false
      store.state.config.bookingsloaded = false
      store.state.config.CacheisLoading = true
      let date = new Date(newdate)
      // if (date.getMonth() !== store.state.config.month_cache.month || !store.state.config.month_cache.initialcacheupdatedone) {
      store.state.config.CacheisLoading = true
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
      // firstDay.setDate(firstDay.getDate() - 10)
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 3, 1)
      // lastDay.setDate(lastDay.getDate() + 10)
      Vue.set(store.state.config.month_cache, 'start', firstDay.formatDate("YYYY-MM-DD"))
      Vue.set(store.state.config.month_cache, 'end', lastDay.formatDate("YYYY-MM-DD"))
      Vue.set(store.state.config.month_cache, 'month', date.getMonth())
      Vue.set(store.state.config.month_cache, 'initialcacheupdatedone', true)
      let dataobject = {
        year: date.getFullYear(),
        start: convertDateForIos(store.state.config.month_cache.start),
        end: convertDateForIos(store.state.config.month_cache.end),
        month: date.getMonth(),
        // companies: store.state.Userdata.companies
        companies: store.state.config.ActiveCompany
      }
      databaseworker.postMessage({'cmd': 'getcalendarcaches','query': dataobject})
      // store.dispatch('dbqueue/addJob', {
      //  id: uid(),
      //   function: 'getcalendarcaches',
      //   description: 'Kalenderdaten laden',
      //   data: dataobject
      // })
      firstDay = null
      lastDay = null
      dataobject = null
      // }
      date = null
    }
  }
})
Dexie.exists('mycrogym_studio_config').then(function (exists) {
  if (exists) {
    initiatemgconfig()
      .then(function (db) {
        mgconfig
          .table('user')
          .toArray()
          .then(function (dataset) {
            for (let i = 0; i < dataset.length; i++) {
              store.state.history.users.push(dataset[i].em)
            }
          })
      })
  }
})
function hash (algo, str) {
  return window.crypto.subtle.digest(algo, new TextEncoder().encode(str))
}
function supportsCrypto () {
  return window.crypto && window.crypto.subtle
}
function encode64 (buff) {
  return btoa(new Uint8Array(buff).reduce((s, b) => s + String.fromCharCode(b), ''))
}
function getUserdata () {
  return new Promise((resolve, reject) => {
    let resource = store.state.tables.user.find(user => user.id === store.state.Userdata.id)
    resolve(resource)
  })
}
function getGlobalUserResourceresourcebyuserid () {
  return new Promise((resolve, reject) => {
    let resource = []
    function checkresult () {
      if (store.state.tables.resources.length > 0 && store.state.Userdata.datasource) {
        resource = store.state.tables.resources.find(resource => resource.userid === store.state.Userdata.datasource.id)
      }
      if (typeof resource === 'object' && typeof resource.id !== 'undefined') {
        resolve(resource)
        resource = null
      } else {
        setTimeout(function () { checkresult() }, 1000)
      }
    }
    checkresult()
  })
}
function getCompanyResourcesByGlobalResourceID (globalresource) {
  return new Promise((resolve, reject) => {
    let resourcecompany = []
    function checkresult2 () {
      if (store.state.tables.resources_companies.length > 0) {
        resourcecompany = store.state.tables.resources_companies.filter(companyresource => companyresource.resourceid === globalresource.id && companyresource.archive === 0)
      }
      if (resourcecompany.length > 0) {
        resolve(resourcecompany)
        resourcecompany = null
      } else {
        setTimeout(function () { checkresult2() }, 1000)
      }
    }
    checkresult2()
  })
}
function getCompanyResourceACLLevel (resourcesofcompanies) {
  let resourcesids = resourcesofcompanies.map(item => (item.id))
  console.log(resourcesids)
  return new Promise((resolve, reject) => {
    function checkresult2 () {
      let level = store.state.Acl.levels_mapping.filter(resource => resourcesids.includes(resource.companyresourceid))
      console.log(level)
      if (typeof level !== 'undefined' && level.length > 0) {
        store.state.Userdata.useracl = level
        resolve(level)
        level = null
        resourcesids = null
      } else {
        setTimeout(function () { checkresult2() }, 1000)
      }
    }
    checkresult2()
  })
}
function getActiveCompanyResourceACLLevelRights (levels) {
  let levelsshort = levels.map(item => (item.acllevelid))
  return new Promise((resolve, reject) => {
    function checkresult3 () {
      let level = store.state.Acl.levels_rights.filter(level => levelsshort.includes(level.aclid))
      if (typeof level !== 'undefined' && level.length > 0) {
        resolve(level)
        level = null
        levelsshort = null
      } else {
        setTimeout(function () { checkresult3() }, 1000)
      }
    }
    checkresult3()
  })
}
function dateTimer () {
  store.state.dateconfig.DateTimer = new Date()
}
setInterval(dateTimer, 4000)
console.log('Modus: ' + process.env.NODE_ENV)
let uri = ''
let socket = Function

// function addrequesttodbworkerqueue () {
//  databaseworker.postMessage({'cmd': 'addrequesttoqueue', 'request': { id: '3',action: '', parameter: {}, timestamp: new Date(), status: 'waiting', resendtoclient: false, result: {} }})
// }
function initSocket (userdata) {
  if (process.env.NODE_ENV === 'development') {
    uri = 'http://192.168.3.22:8085'
    socket = socketio(uri, {
      path: '/data',
      query: {
        token: userdata.at
      },
      auth: {
        token: userdata.at,
        userid: userdata.id,
        deviceid: userdata.di
      },
      'force new connection': true,
      forceNew: true,
      secure: true,
      reconnection: true,
      autoConnect: false,
      transports: ['websocket']
    })
  } else if (process.env.NODE_ENV === 'production') {
    uri = 'https://sppapi2.mycrogym.com'
    socket = socketio(uri, {
      path: '/data',
      query: {
        token: userdata.at
      },
      auth: {
        token: userdata.at,
        userid: userdata.id,
        deviceid: userdata.di
      },
      'force new connection': true,
      forceNew: true,
      secure: true,
      reconnection: true,
      autoConnect: false,
      transports: ['websocket']
    })
  }
}
function init (userdata) {
  console.log('init Function')
  if (store.state.config.initalscriptrequestdone === false) {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      store.state.pwa.systemreadytoaddapptohome = true
      console.log('PWA ready for install')
    })
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
    window.addEventListener('appinstalled', (evt) => {
      store.state.pwa.appinstalledtohome = true
    })
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === 'visible') {
        databaseworkerservercom.postMessage({'cmd': 'visibility', 'task': 'visible'})
        databaseworker.postMessage({'cmd': 'visibility', 'task': 'visible'})
        // store.dispatch('refreshcalendarcaches2', store.state.dateconfig.ActiveDate)
        // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
      } else if (document.visibilityState === 'hidden') {
        store.commit('updateactiveviewsettings', { companyid: store.state.config.ActiveCompany, activelocation: store.state.config.ActiveLocation, activeroom: store.state.config.ActiveRoom })
        databaseworker.postMessage({'cmd': 'visibility', 'task': 'hidden'})
        console.log(store.state.config.serverconnectionlostdate)
        if (store.state.config.serverconnectionlostdate !== '') {
          // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
          // let minutesoffline = getMinutesBetweenDates(new Date(store.state.config.serverconnectionlostdate), new Date())
          // if (minutesoffline > 5) {
          //   store.commit('logoutbyuser')
          //  } else {
          // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
          // store.dispatch('refreshcalendarcaches2', store.state.dateconfig.ActiveDate)
          // }
        }
        // if (socket.connected === false) socket.open()
      }
    })
    if (window.Worker) {
      // databaseworker = new Worker('/public/js/db_q.js')
      // databaseworker = new Worker('/public/js/db_q.js', {type: 'module'})
      databaseworkerservercom.addEventListener('message', function (e) {
        console.log(e.data.cmd)
        switch (e.data.cmd) {
          case "initsuccess":
            console.log('initsuccess databaseworkerservercom')
            store.state.pwa.databaseworkerready = true
            break
          case "pushProxy":
            let data = e.data.data
            // console.log(store.state.serverqueue.active.id)
            // if (store.state.serverqueue.active.id && store.state.serverqueue.active.id === data.result.id) store.dispatch('serverqueue/startNextJob')
            switch (data.cmd) {
              case "new":
                {
                  Vue.set(store.state.tables[data.table], store.state.tables[data.table].length, data.result)
                  if (data.table === 'media_files') {
                    let index = store.state.fileuploadqueue.findIndex(file => file.id === data.result.id)
                    Vue.set(store.state.fileuploadqueue, index, data.result)
                    index = null
                  }
                  if (data.table === 'calendar_events') {
                    data.result.datestart = convertDateForIos(data.result.datestart)
                    data.result.dateend = convertDateForIos(data.result.dateend)
                    if (new Date(data.result.datestart) >= new Date(store.state.config.month_cache.start) && new Date(data.result.datestart) <= new Date(store.state.config.month_cache.end)) {
                      Vue.set(store.state.Calendar_events_month_cache, store.state.Calendar_events_month_cache.length, data.result)
                    }
                    Vue.set(store.state.modaleventbookings, store.state.modaleventbookings.length, data.result)
                  }
                  if (data.table === 'calendar_bookings') {
                    if (store.state.config.ActiveClient !== '' && store.state.config.ActiveClient !== null) {
                      // Vue.set(state.Calendar_bookings_by_activeclient, state.Calendar_bookings_by_activeclient.length, data.result)
                      store.dispatch('getcalendarbookingsbyactiveclient')
                    }
                    data.result.date = convertDateForIos(data.result.date)
                    if (new Date(data.result.date) >= new Date(store.state.config.month_cache.start) && new Date(data.result.date) <= new Date(store.state.config.month_cache.end)) {
                      Vue.set(store.state.Calendar_bookings_month_cache, store.state.Calendar_bookings_month_cache.length, data.result)
                    }
                  }
                  if (data.table === 'newcompaniesaclmapping') databaseworker.postMessage({'cmd': 'refreshuseracldata', 'userid': store.state.Userdata.datasource, 'activecompany': store.state.config.ActiveCompany})
                  if (data.table === 'communication_outbox_internal_emails') {
                    store.state.communication_outbox_internal_emails.push(data.result)
                  }
                }
                break
              case "update":
                {
                  console.log('update')
                  let index = store.state.tables[data.table].findIndex(item => item.id === data.result.id)
                  Vue.set(store.state.tables[data.table], index, data.result)
                  index = null
                  if (data.table === 'media_files') {
                    let index = store.state.fileuploadqueue.findIndex(file => file.id === data.result.id)
                    Vue.set(store.state.fileuploadqueue, index, data.result)
                    index = null
                  }
                  if (data.table === 'calendar_events') {
                    console.log(data)
                    data.result.datestart = convertDateForIos(data.result.datestart)
                    data.result.dateend = convertDateForIos(data.result.dateend)
                    if (new Date(data.result.datestart) >= new Date(store.state.config.month_cache.start) && new Date(data.result.datestart) <= new Date(store.state.config.month_cache.end)) {
                      let index2 = store.state.Calendar_events_month_cache.findIndex(item => item.id === data.result.id)
                      Vue.set(store.state.Calendar_events_month_cache, index2, data.result)
                      index2 = null
                    }
                  }
                  if (data.table === 'calendar_bookings') {
                    data.result.date = convertDateForIos(data.result.date)
                    if (store.state.config.ActiveClient !== '' && store.state.config.ActiveClient !== null) {
                      console.log('Ja')
                      // let index = state.Calendar_bookings_by_activeclient.findIndex(item => item.id === data.result.id)
                      // if (index) Vue.set(state.Calendar_bookings_by_activeclient, index, data.result)
                      store.dispatch('getcalendarbookingsbyactiveclient')
                    }
                    if (new Date(data.result.date) >= new Date(store.state.config.month_cache.start) && new Date(data.result.date) <= new Date(store.state.config.month_cache.end)) {
                      let index2 = store.state.Calendar_bookings_month_cache.findIndex(item => item.id === data.result.id)
                      if (index2) Vue.set(store.state.Calendar_bookings_month_cache, index2, data.result)
                      index2 = null
                      let index3 = store.state.modaleventbookings.findIndex(item => item.id === data.result.id)
                      if (index3) Vue.set(store.state.modaleventbookings, store.state.modaleventbookings.length, data.result)
                      index3 = null
                    }
                  }
                  if (data.table === 'communication_outbox_internal_emails') {
                    let index2 = store.state.communication_outbox_internal_emails.findIndex(item => item.id === data.result.id)
                    if (index2) {
                      Vue.set(store.state.communication_outbox_internal_emails, index2, data.result)
                    } else {
                      store.state.communication_outbox_internal_emails.push(data.result)
                    }
                    index2 = null
                  }
                }
                break
              }
              data = null
            break
          case "socketconnection":
            console.log('socketconnection')
            console.log(e.data.area)
            console.log(e.data.status)
            Vue.set(store.state.config, 'isConnected', e.data.status)
            break
          case "tables":
            console.log('tables arrived')
            if (store.state.config.tablesarrived === false) {
              Vue.set(store.state.config, 'tablesarrived', true)
              store.state.config.tables = e.data.results
            }
            break
          case "loading":
            store.commit('setdbpwaloadingstatus', e.data.payload)
            if (e.data.payload.status === 'done') {
              if (e.data.payload.missingtables.length > 0) console.log(e.data.payload.missingtables)
              if (e.data.payload.modus === 'serverrefresh') store.state.modals.loadingstatus.show = false
              console.log('server.worker.js loading done')
              getidbuserdata()
                .then(function (userdata) {
                  console.log(userdata && userdata.id)
                  if (userdata && userdata.id) {
                    databaseworker.postMessage({'cmd': 'getuseracldata2', 'userid': userdata.id, 'deviceid': userdata.di, 'activecompany': store.state.config.ActiveCompany})
                  }
                })
                store.state.config.initalserverdatarequestdone = true
            }
            break
        }
      })
      databaseworkerservercom.postMessage({'cmd': 'init'})
      databaseworker.addEventListener('message', function (e) {
        let bookings = []
        switch (e.data.cmd) {
          case "initsuccess":
            console.log('initsuccess databaseworker')
            store.state.pwa.databaseworkerready = true
            break
          case "customrequest":
            console.log('customrequest')
            console.log(e.data)
            console.log(store.state.dbqueue.db_active.id)
            store.state.customrequests.push({'id': e.data.id, 'function': e.data.function, 'config': e.data.config, 'data': JSON.parse(e.data.data)})
            console.log(store.state.customrequests)
            store.dispatch('dbqueue/startNextJob')
            break
          case "loading":
            // store.commit('setdbpwaloadingstatus', e.data.payload)
            if (e.data.payload.status === 'done') {
              console.log('query.worker.js loading done')
              // databaseworker.postMessage({'cmd': 'getuseracldata', 'userid': userdata.id, 'deviceid': userdata.di, 'activecompany': userdata.ac})
              store.state.config.initalworkerdatarequestdone = true
              // store.state.config.isLoading = false
              if (store.state.config.hasLoaded === false) store.state.config.hasLoaded = true
              // store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
            }
            break
          case "calendarbookingsbyactiveclient":
            bookings = JSON.parse(e.data.bookings)
            for (let i = 0; i < bookings.length; i++) {
              bookings[i].date = convertDateForIos(bookings[i].date)
            }
            store.state.Calendar_bookings_by_activeclient = Object.assign([],bookings)
            bookings = null
            break
          case "internalemailsbyactiveclient":
            console.log('Internalemailsbyactiveclient')
            store.state.communication_outbox_internal_emails = JSON.parse(e.data.emails)
            break
          case "modaleventcontracts":
            console.log('modaleventcontracts')
            store.state.modaleventcontracts =JSON.parse(e.data.contracts)
            break
          case "modaleventbookings":
            bookings = JSON.parse(e.data.bookings)
            for (let i = 0; i < bookings.length; i++) {
              bookings[i].date = convertDateForIos(bookings[i].date)
            }
            store.state.modaleventbookings = Object.assign([],bookings)
            bookings = null
            break
          case "useracldata":
            console.log('useracldata')
            console.log(e.data.sub)
            let data = JSON.parse(e.data.data)
            switch (e.data.sub) {
              case 'UserNotAvailableInTables':
                console.log('UserNotAvailableInTables')
                databaseworkerservercom.postMessage({'cmd': 'getusertable'})
                break
              case "datasource":
                Vue.set(store.state.Userdata, 'datasource', data)
                break
              case "UserCompaniesIds":
                Vue.set(store.state.Userdata, 'UserCompaniesIds', data)
                break
              case "CompaniesResources":
                Vue.set(store.state.Userdata, 'CompaniesResources', data)
                break
              case "ActiveCompanyResource":
                Vue.set(store.state.Userdata, 'ActiveCompanyResource', data)
                break
              case "ActiveUserCompanyACLLevel":
                Vue.set(store.state.config, 'ActiveUserCompanyACLLevel', data)
                break
              case "ActiveUserCompanyACLRights":
                console.log('ActiveUserCompanyACLRights')
                console.log(data)
                Vue.set(store.state.config, 'isLoading', false)
                Vue.set(store.state.config, 'ActiveUserCompanyACLRights', data)
                Vue.set(store.state.config, 'rightsloaded', true)
                if (!store.state.config.isLoading) store.dispatch('refreshcalendarcaches', store.state.dateconfig.ActiveDate)
                break
              case "ChangeActiveCompany":
                console.log(data)
                console.log(store.state.config.ActiveCompany)
                console.log(store.state.config.ActiveLocation)
                if (store.state.config.ActiveCompany === '') {
                  Vue.set(store.state.config, 'ActiveCompany', data)
                }
                break
            }
            data = null
            break
          case "eventscache":
            console.log('eventscache')
            let events = JSON.parse(e.data.events)
            console.log(events.length)
            for (let i = 0; i < events.length; i++) {
              events[i].datestart = convertDateForIos(events[i].datestart)
              events[i].dateend = convertDateForIos(events[i].dateend)
              events[i].created = convertDateForIos(events[i].created)
              events[i].updated = convertDateForIos(events[i].updated)
            }
            store.state.Calendar_events_month_cache = Object.assign([],events)
            store.state.config.eventsloaded = true
            events = null
            break
          case "bookingscache":
            console.log('bookingscache')
            bookings = JSON.parse(e.data.bookings)
            console.log(bookings.length)
            for (let i = 0; i < bookings.length; i++) {
              bookings[i].date = convertDateForIos(bookings[i].date)
              bookings[i].created = convertDateForIos(bookings[i].created)
              bookings[i].updated = convertDateForIos(bookings[i].updated)
            }
            store.state.Calendar_bookings_month_cache = null
            store.state.Calendar_bookings_month_cache = Object.assign([],bookings)
            bookings = null
            store.state.config.bookingsloaded = true
            store.state.config.isLoading = false
            store.state.config.CacheisLoading = false
            // if (socket.connected === false) store.commit('connect_socket')
            break
          // case "renewaccesstoken":
          //  console.log('renewaccesstoken DBW request')
          //  store.commit('accesstoken_request')
          //  break
          case "tables":
            console.log('tablesarrived')
            if (store.state.config.tablesarrived === false) {
              Vue.set(store.state.config, 'tablesarrived', true)
              store.state.config.tables = Object.assign([],e.data.results)
            }
            break
          case "tableupdate":
            store.state.tables[e.data.table] = JSON.parse(e.data.results)
            let index = store.state.config.tables.findIndex(table => table.name === e.data.table)
            store.state.config.tables[index].loaded = true
            let opentables = Object.assign([],store.state.config.tables.filter(table => table.loaded === false))
            if (opentables.length === 0) {
              console.log('alles da')
              console.log(opentables)
              store.commit('setlocationbyactivecompany')
              if (!store.state.config.initalworkerdatarequestdone) {
                store.commit('updateactivesettings')
                updateuseridbsettings('activesettingsperview')
                if (store.state.config.lasturlpath === '') {
                  router.push('/dashboard')
                } else {
                  router.push(store.state.config.lasturlpath)
                }
                Vue.set(store.state.config, 'initalworkerdatarequestdone', true)
                // store.dispatch('setaclrightsbyacllevel')
              }
              databaseworker.postMessage({'cmd': 'cachereceived'})
              for (let i = 0; i < store.state.config.tables.length; i++) {
                store.state.config.tables[i].loaded = false
              }
              if (store.state.config.hasLoaded === false) store.state.config.hasLoaded = true
              store.dispatch('refreshcalendarcaches2', store.state.dateconfig.ActiveDate)
            }
            index = null
            opentables = null
        }
      }, false)
      fileupload = new Worker('/js/fileupload.worker.js')
      fileupload.addEventListener('message', function (e) {
        switch (e.data.cmd) {
          case "statusfileupload":
            break
        }
      }, false)
      generalworker = new Worker('/js/general.worker.js')
      generalworker.addEventListener('message', function (e) {
        switch (e.data.cmd) {
          case "createdpdf":
            store.state.pwa.pdfcache = e.data.msg
            store.state.pwa.pdfreceived = true
            break
        }
      }, false)
    }
  }
}
function updateOnlineStatus (event) {
  if (event.type === 'offline') {
    // socket.disconnect()
    // Abfrage ob PWA?
    databaseworker.postMessage({'cmd': 'network', 'task': 'offline'})
    databaseworkerservercom.postMessage({'cmd': 'network', 'task': 'offline'})
    Vue.set(store.state.config, 'isOnline', 'offline')
  } else if (event.type === 'online') {
    databaseworker.postMessage({'cmd': 'network', 'task': 'online'})
    databaseworkerservercom.postMessage({'cmd': 'network', 'task': 'online'})
    if (store.state.config.isOnline === 'offline') {
      Vue.set(store.state.config, 'isOnline', 'online')
      if (socket && socket.connected && socket.connected === false) {
        console.log('Reconnect after loosing online connection')
        let data = []
        data.token = store.state.config.token
        data.userid = store.state.Userdata.datasource.id
        data = null
        // store.commit('connect_socket', data)
      } else {
        // Vue.set(store.state.config, 'isOnline', 'online')
      }
    }
  }
}
// PWA
if ((window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone === true)) {
  store.state.pwa.applaunchedfromhomescreen = true
}
async function checkpwastatus () {
  const relatedApps = await navigator.getInstalledRelatedApps()
  const PWAisInstalled = relatedApps.length > 0
  console.log(relatedApps)
  console.log(PWAisInstalled)
  return PWAisInstalled
}
// console.log(checkpwastatus())
function transformitemsfordb (data) {
  let result = ''
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (i > 0) {
        result = result + ',' + data[i]
      } else {
        result = data[i]
      }
    }
  }
  return result
}
async function checkifcompaniesindb (companies) {
  for (let i = 0; i < companies.length; i++) {
    mgconfig
      .table('companies')
      .get({id: companies[i]})
      .then(function (result) {
        if (!result) {
          let soy = new Date(new Date().getFullYear(), 0, 1)
          let eoy = new Date(new Date().getFullYear(), 11, 31)
          let entry = {
            id: companies[i],
            datestart: soy.formatDate('YYYY-MM-DD HH:mm:ss'),
            dateend: eoy.formatDate('YYYY-MM-DD HH:mm:ss'),
            lastupdate: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            spk: '', // Server Private Key
            cpuk: '', // Client Public Key
            cprk: '', // Client Public RefreshKey
            ii: false,
            active: true
          }
          mgconfig
            .table('companies')
            .put(entry)
          soy = null
          eoy = null
          entry = null
          return entry
        } else {
          return result
        }
      })
  }
}
function ab2str (buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

/*
Export the given key and write it into the "exported-key" space.
*/
// async function exportCryptoKey (key) {
//  const exported = await window.crypto.subtle.exportKey(
//    "spki",
//    key
//  )
//  const exportedAsString = ab2str(exported)
//  const exportedAsBase64 = window.btoa(exportedAsString)
//  return `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`
// }
async function insertnewuser (data, hashedpw) {
  // Datensatz erstellen
  console.log('insertnewuser')
  console.log(data)
  let newusertable = {
    id: data.userid,
    em: data.email,
    pw: hashedpw,
    at: data.token, // AccessToken
    rt: data.rtoken, // RefreshToken
    pk: data.privatekeystore, // PrivateKey key.privateKey
    sk: data.serverpublickey, // Server Public Key
    cs: data.companies, // Companies
    ac: data.companies[0], // ActiveCompany
    di: data.deviceid, // DeviceID
    vs: '',
    kl: false // KeepLoggegIn
  }
  console.log(newusertable)
  console.log(mgconfig.isOpen())
  const result = await mgconfig
    .table('user')
    .put(newusertable)
    .then(function (result) {
      console.log('c')
      console.log(result)
      return result
    })
  newusertable = null
  console.log(result)
  return result
}
async function updateexistinguser (data, hashedpw) {
  // Datensatz erstellen
  console.log('updateexistinguser')
  console.log(data)
  let newusertable = {
    id: data.userid,
    em: data.email,
    pw: hashedpw,
    at: data.token, // AccessToken
    rt: data.rtoken, // RefreshToken
    pk: data.privatekeystore, // PrivateKey key.privateKey
    sk: data.serverpublickey, // Server Public Key
    cs: data.companies, // Companies
    ac: data.companies[0], // ActiveCompany
    di: data.deviceid, // DeviceID
    vs: '' // ViewSettings
  }
  const result = await mgconfig
    .table('user')
    .where('id')
    .equals(data.userid)
    .modify(newusertable)
    .then(function (result) {
      console.log('Existing User Updated')
      console.log(result)
      return result
    })
  newusertable = null
  return result
}
async function updateuseridbsettings (parameter, values) {
  // Datensatz erstellen
  const result = await mgconfig
    .table('user')
    .get({id: store.state.Userdata.id})
    .then(function (result) {
      console.log(result)
      if (result) {
        if (parameter === 'deviceid') {
          result.di = values
        } else if (parameter === 'activesettingsperview') {
          result.vs = store.state.activesettingsperview
        } else if (parameter === 'tokens') {
          result.at = values.at
          result.rt = values.rt
        }
        result.ac = store.state.config.ActiveCompany
        mgconfig
          .table('user')
          .put(result)

      }
    })
  return result
}
async function getidbuserdata () {
  let result = await mgconfig
    .table('user')
    .get({id: store.state.Userdata.id})
  return result
}
async function updateactivecompany (activecompany) {
  console.log(activecompany)
  console.log(store.state.Userdata.id)
  let result = await mgconfig
    .table('user')
    .where('id')
    .equals(store.state.Userdata.id)
    .modify({ ac: activecompany })
    .catch((error) => {
     console.error(error)
    })
  return result
}
async function hashPassword (user) {
  let password = user.password
  let hashedPassword = ''
  if (supportsCrypto()) {
    hashedPassword = await new Promise((resolve, reject) => {
      hash('SHA-256', password).then(hashed => {
        resolve(encode64(hashed))
      })
    })
  } else {
    hashedPassword = await new Promise((resolve, reject) => {
      let salt = bcrypt.genSaltSync(10)
      bcrypt.hash(password, salt, null, function (err, hash) {
        if (err) reject(err)
        resolve(hash)
      })
    })
  }
  password = null
  return hashedPassword
}
// function forceSWupdate () {
//  if ('serviceWorker' in navigator) {
//    navigator.serviceWorker.getRegistrations().then(function (registrations) {
//      for (let registration of registrations) {
//        registration.update()
//      }
//    })
//  }
// }

let worker = false
if ('serviceWorker' in navigator && worker) {
  store.state.config.serviceworkeravaiable = true
  window.addEventListener('load', function () {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage("hi")
    } else {
      navigator.serviceWorker.register("/js/service.worker.js").then( (registration) => {
       setInterval( () => registration.update(), 86400 )
     })
    }
    navigator.serviceWorker.addEventListener('message', function (event) {
      console.log("Got reply from service worker: " + event.data)
      switch (event.data.cmd) {
        case "newpwafiles":
          console.log("New PWAFiles successfully cached: " + event.data.status)
      }
    })
    if (navigator.serviceWorker.controller) {
      console.log("Sending 'hi' to controller")
      navigator.serviceWorker.controller.postMessage("hi")
    }
  })
}
var generalworker
var fileupload
// Router
router.beforeEach((to, from, next) => {
  console.log(from.path)
  console.log(to.path)
  if (from.fullPath === to.fullPath) {
    next(false)
  }
  if (store.state.config.isLoggedin === false) {
    if (from.path === '/login' && to.path === '/settings') {
      next()
    }
    if (to.path !== '/login') {
      store.commit('setlasturlpath',to.path)
    }
    if (to.path !== '/login' && from.path !== '/login' && from.path !== '/') {
      store.commit('setlasturlpath',to.path)
      next({
        path: '/login',
        query: {
          redirect: from.path
        }
      })
    }
    if (to.path !== '/login' && (from.path === '/' && to.path !== '/settings')) {
      next({
        path: '/login'
      })
    }
    if (from.path === '/login' && to.path !== '/settings') {
      next(false)
    }
  } else {
    if (store.state.config.ActiveUserCompanyACLRights.length) {
      let viewobject = []
      let viewsstring = store.state.config.ActiveUserCompanyACLRights.find(acl => acl.action === 'views')
      if (typeof viewsstring !== 'undefined') {
        viewobject = viewsstring.val.split(",")
      }
      viewsstring = null
      if (to.path === '/kunden' && store.state.config.ActiveClient !== '') {
        next({
          path: '/kunde'
        })
      }
      if (viewobject.includes(to.name)) {
        next()
      } else {
        next(false)
      }
      viewobject = null
    } else {
      if (to.path === '/dashboard') {
        next()
      }
    }
  }
  next()
})
// Helper to create uid.
function s4 () {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const uid = function () {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4()
}
Vue.directive('can', function (el, binding, vnode) {
  if (store.state.config.rightsloaded) {
    const behaviour = binding.modifiers.disable ? 'disable' : 'hide'
    if (store.state.config.ActiveUserCompanyACLRights.length) {
      if (binding.arg === 'view') {
        let viewobject = {}
        let viewsstring = store.state.config.ActiveUserCompanyACLRights.find(acl => acl.action === 'views').val
        if (typeof viewsstring !== 'undefined') {
          viewobject = viewsstring.split(",")
        }
        if (viewobject.includes(binding.expression.slice(1, -1))) {
          el.style.display = ''
          el.disabled = false
        } else {
          if (behaviour === 'hide') {
            el.style.display = 'none'
          } else if (behaviour === 'disable') {
            el.disabled = true
          }
        }
        viewsstring = null
        viewobject = null
      }
    }
  }
})

Vue.directive('dropzone', {
  bind: function (el, binding, vnode) {
    interact('.column')
      .dropzone({
      })
      .on('dragenter', function (event) {
        console.log('dragenter')
        event.target.classList.add('drop-activated')
        event.currentTarget.style.width = event.target.style.width
      })
      .on('dragleave', function (event) {
        event.target.classList.remove('drop-activated')
      })
      .on('drop', function (event) {
        event.stopImmediatePropagation()
        event.target.classList.remove('drop-activated')
        console.log(event)
        let datasetdraggedelement = event.relatedTarget.dataset
        let datasetdropzone = event.currentTarget.dataset
        let timeformat = datasetdropzone.time.split(':')
        let originaldatestart = new Date(convertDateForIos(datasetdropzone.date))
        let newdatestart = new Date(originaldatestart.setHours(timeformat[0],timeformat[1],0))
        console.log(originaldatestart)
        console.log(datasetdraggedelement)
        console.log(datasetdropzone)
        let equipmentid = ""
        let equipmentarray = datasetdraggedelement.equipment.split(",")
        console.log(equipmentarray)
        if (equipmentarray.length === 1) {
          equipmentid = Object.assign("",equipmentarray[0])
        } else if (equipmentarray.length > 1) {
          // Wenn ja, dann prüfen ob Geräte zum gleichen Zeitpunkt verwendet werden
          equipmentid = Object.assign("",equipmentarray[0])
        }
        if (datasetdraggedelement.id === "") {
          console.log(event.relatedTarget.dataset)
          console.log(timeformat)
          let newdateend = new Date(newdatestart).setMinutes(new Date(newdatestart).getMinutes() + Number(datasetdraggedelement.duration))
          let logs = []
          let log = {
            date: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
            kindof: 'datetimemanagement',
            note: 'Initial Creation',
            user: store.state.Userdata.datasource.id
          }
          logs.push(log)
          let eventnew = {
            id: uid(),
            companyid: event.relatedTarget.dataset.company,
            locationid: event.relatedTarget.dataset.location,
            roomid: '',
            equipmentid: datasetdraggedelement.equipment,
            datestartisotimezone: new Date(newdatestart).toISOString(),
            dateendisotimezone: new Date(newdateend).toISOString(),
            datestart: new Date(newdatestart).toIsoString(),
            dateend: new Date(newdateend).toIsoString(),
            serviceid: event.relatedTarget.dataset.service,
            log: JSON.stringify(logs),
            notes: '',
            created: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
            createdbyid: store.state.Userdata.datasource.id,
            updated: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
            updatedbyid: store.state.Userdata.datasource.id,
            archive: 0
          }
          if (store.state.dateconfig.CalendarView === 'week') {
            eventnew.roomid = event.relatedTarget.dataset.room
          } else if (store.state.dateconfig.CalendarView === 'day') {
            eventnew.roomid = event.currentTarget.dataset.roomid
          }
          if (datasetdropzone.equipment) eventnew.equipmentid = datasetdropzone.equipment
          console.log(eventnew)
          // store.dispatch('serverqueue/addJob', {
          //  id: eventnew.id,
          //  function: 'NewCalendarEventDate',
          //  description: 'Event hinzufügen',
          //  data: eventnew
          // })
          databaseworkerservercom.postMessage({'cmd':'socketProxy','function': 'NewCalendarEventDate', 'data': eventnew })
        } else if (datasetdraggedelement.id !== "") {
          // Nach Event Suchen
          let eventold = Object.assign({},store.state.Calendar_events_month_cache.find(eventold => eventold.id === event.relatedTarget.dataset.id))
          let logs = []
          try {
            logs = JSON.parse(eventold.log)
          } catch (e) {
            logs = []
          }
          let log = {
            date: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
            kindof: 'datetimemanagement',
            note: 'Update Date/Time by Dropping',
            user: store.state.Userdata.datasource.id
          }
          logs.push(log)
          eventold.log = JSON.stringify(logs)
          let minutes = newdatestart.getMinutes()
          let newdateend = new Date(newdatestart.valueOf())
          let difference = new Date(datasetdraggedelement.end).getTime() - new Date(datasetdraggedelement.start).getTime()
          let duration = Math.round(difference / 60000)
          newdateend.setMinutes(minutes + Number(duration))
          let equipmentarray = eventold.equipmentid.split(",")
          if (datasetdropzone.equipment && equipmentarray && !equipmentarray.includes(datasetdropzone.equipment)) eventold.equipmentid = datasetdropzone.equipment
          if (datasetdropzone.roomid) eventold.roomid = datasetdropzone.roomid
          eventold.datestartisotimezone = new Date(newdatestart).toISOString()
          eventold.dateendisotimezone = new Date(newdateend).toISOString()
          eventold.datestart = new Date(newdatestart).toIsoString()
          eventold.dateend = new Date(eventold.created).toIsoString()
          eventold.created = new Date().formatDate("YYYY-MM-DD HH:mm:ss")
          eventold.updated = new Date().formatDate("YYYY-MM-DD HH:mm:ss")
          // store.dispatch('serverqueue/addJob', {
          //   id: eventold.id,
          //   function: 'UpdateCalendarEventDate',
          //   description: 'Event aktualisieren',
          //   data: eventold
          // })
          databaseworkerservercom.postMessage({'cmd':'socketProxy','function': 'UpdateCalendarEventDate', 'data': eventold })
          eventold = null
          // per Service worker aktualisieren
        }
      })
  },
  unbind: function (el) {
    interact('.column').unset()
  }
})
Vue.directive('interact', {
  bind: function (el, binding, vnode) {
    let screenX = el.getAttribute("data-x")
    let screenY = el.getAttribute("data-y")
    let originalX = 0
    let originalY = 0
    let original
    let element
    const position = { x: 0, y: 0 }
    interact(el).draggable({
      // enable inertial throwing
      manualStart: true,
      autoScroll: false,
      hold: 2,
      listeners: {
        start(event) {
          position.x = event.dx
          position.y = event.dy
          let dataobj = {}
          console.log(binding)
          if (binding.arg === 'existing') {
            if (binding.value && binding.value.calendarevent) dataobj = binding.value.calendarevent
          } else if (binding.arg === 'new') {
            if (binding.value && binding.value.service) dataobj = Object.assign({},binding.value.service)
          }
          if (binding.arg === 'new') {
            dataobj.new = true
          } else {
            dataobj.new = false
          }
          console.log(dataobj)
          store.commit('setdraggedelement', { drag: true, autoopenmodal: true, dataobj: dataobj })
          store.commit('setdaysdropzone', true)
          dataobj = null
        },
        move(event) {
          position.x += event.dx
          position.y += event.dy
          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`
          event.target.style.position = "absolute"
          event.target.style.zIndex = "1060"
        },
      }
    })
    .on('doubletap', function (event) {
      event.preventDefault()
      console.log('geht')
      console.log(binding.value)
      if (binding.arg === 'existing') {
        store.commit('sethoverservice','')
        store.commit('modaleditevent', {show: true, dataobject: Object.assign({},binding.value.calendarevent), times: ''})
      }
    })
    .on("down", function(event) {
      console.log('start')
      // event.stopImmediatePropagation()
      // event.preventDefault()
    })
    .on("move", function(event) {
      const { currentTarget, interaction } = event
      original = currentTarget
      const { offsetTop, offsetLeft } = currentTarget
      if (interaction.pointerIsDown && !interaction.interacting() && currentTarget.style.transform === "") {
        element = currentTarget.cloneNode(true)
        element.style.position = "absolute"
        element.style.zIndex = "1060"
        element.style.width = store.state.dateconfig.cellwidth + "px"
        element.style.left = original.getBoundingClientRect().left + "px"
        element.style.top = original.getBoundingClientRect().top + "px"
        original.style.borderStyle = "dotted"
        original.style.borderColor = currentTarget.style.backgroundColor
        original.style.backgroundColor = "#ffffff"
        let container = document.querySelector(".app-body")
        console.log(container.offsetTop + " " + container.offsetLeft)
        container && container.appendChild(element)
        position.x = offsetLeft
        position.y = offsetTop
      } else if (interaction.pointerIsDown && !interaction.interacting()) {
        let x =
          (parseFloat(element.getAttribute("data-x")) || 0) +
          element.dx,
        y =
          (parseFloat(element.getAttribute("data-y")) || 0) +
          element.dy
        // translate the element
        position.x = offsetLeft
        position.y = offsetTop
        element.style.webkitTransform = element.style.transform =
          "translate(" + x + "px, " + y + "px)";
        const regex = /translate\(([\d]+)px, ([\d]+)px\)/i
        const transform = regex.exec(currentTarget.style.transform)
        if (transform && transform.length > 1) {
          position.x = Number(transform[1])
          position.y = Number(transform[2])
        }
        element.setAttribute("data-x", position.x)
        element.setAttribute("data-y", position.y)
      }
      interaction.start({ name: 'drag' }, event.interactable, element)
    })
    .on('dragend', function (event) {
      const { currentTarget, interaction } = event
      original.style.backgroundColor = original.style.borderColor
      original.style.borderStyle = ""
      currentTarget.remove()
      original = null
      element = null
      store.commit('setdaysdropzone', false)
      store.commit('setdraggedelement', { drag: false, autoopenmodal: false, dataobj: {} })
    })
  },
  unbind: function (el) {
    interact(el).unset()
  }
})
function beforedestroy () {
  window.removeEventListener('beforeinstallprompt', null)
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
  window.removeEventListener('appinstalled', null)
  document.removeEventListener("visibilitychange", null)
  generalworker.removeEventListener("message", null)
  updateuseridbsettings('activesettingsperview')
}
sync(store, router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  locale: 'de',
  router,
  store: store,
  Multiselect,
  template: '<App/>',
  beforeDestroy () {
    beforedestroy()
  },
  data () {
    return {
      calendar_events: [],
      calendar_bookings: []
    }
  },
  components: {
    App
  }
})
